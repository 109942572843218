/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { ContentState, DraftEditorCommand, DraftHandleValue, Editor, EditorState, RichUtils } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import LinkDecorator from './RichContentEditableLinkDecorator';
import RichContentEditableToolbar from './RichContentEditableToolbar';

export interface Message {
  bodyAsText?: string;
  bodyAsHtml?: string;
}

export interface Props {
  inputId: string;
  onChange: (value: Message) => void;
  message: Message;
  placeholder?: string;
  disabled?: boolean;
}

const RichContentEditable: React.FC<Props> = (props) => {
  const { inputId, onChange, message, placeholder, disabled } = props;
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty(LinkDecorator));

  useEffect(() => {
    const currentContent = stateToHTML(editorState.getCurrentContent());

    if (currentContent !== message.bodyAsHtml) {
      if (message.bodyAsHtml) {
        setEditorState(EditorState.createWithContent(stateFromHTML(message.bodyAsHtml), LinkDecorator));
      } else {
        setEditorState(
          EditorState.createWithContent(ContentState.createFromText(message.bodyAsText ?? ''), LinkDecorator),
        );
      }
    }
    // Disabling exhaustive dependency check. Should use provided editor state rather than local
    // https://draftjs.org/docs/advanced-topics-editorstate-race-conditions/#best-practices
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.bodyAsHtml, message.bodyAsText]);

  const onContentChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const contentAsHtml = stateToHTML(newEditorState.getCurrentContent());
    onChange({ bodyAsHtml: contentAsHtml });
  };

  const handleKeyCommand = (command: DraftEditorCommand, newEditorState: EditorState): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(newEditorState, command);
    if (newState) {
      onContentChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  return (
    <div id={inputId} className="RichEditor-container">
      <RichContentEditableToolbar editorState={editorState} onContentChange={onContentChange} disabled={disabled} />
      <div className="RichEditor-divider" />
      <div className="RichEditor-editor panel-body">
        <Editor
          editorState={editorState}
          onChange={onContentChange}
          handleKeyCommand={handleKeyCommand}
          placeholder={placeholder}
          readOnly={disabled}
        />
      </div>
    </div>
  );
};

export default RichContentEditable;
