import { createContext, useContext, useEffect, useState } from 'react';
import AdvancedSearchOption from '../models/AdvancedSearchOption';
import BasicSearchOption from '../models/BasicSearchOption';
import { AdvancedSearchProvider } from './useAdvancedSearchCriteria';
import { BasicSearchProvider } from './useBasicSearchCriteria';
import Authorization from '../../security-profiles/models/Authorization';

interface Props {
  advancedSearchOptions: AdvancedSearchOption[];
  basicSearchOptions: BasicSearchOption[];
  securityProfileAuthorizations?: Authorization[];
  children?: React.ReactNode;
  isOps?: boolean;
}

type CurrentSearchType = 'basic' | 'advanced';
const SESSION_KEY = 'searchType';

const CurrentSearchTypeContext = createContext<CurrentSearchType>('basic');
const UseSearchProviderUpdaterContext = createContext<(currentSearchType: CurrentSearchType) => void>(() => {});

const UseEarningsSearchProvider = (props: Props) => {
  const [searchType, setSearchType] = useState<CurrentSearchType>(
    () => (sessionStorage.getItem(SESSION_KEY) || 'basic') as CurrentSearchType,
  );
  useEffect(() => {
    sessionStorage.setItem(SESSION_KEY, searchType);
  }, [searchType]);

  const { advancedSearchOptions, basicSearchOptions, securityProfileAuthorizations, children, isOps } = props;

  return (
    <UseSearchProviderUpdaterContext.Provider value={setSearchType}>
      <CurrentSearchTypeContext.Provider value={searchType}>
        <AdvancedSearchProvider searchOptions={advancedSearchOptions} sessionKey="earningsAdvancedSearchCriteria">
          <BasicSearchProvider
            authorizations={securityProfileAuthorizations}
            searchOptions={basicSearchOptions}
            isOps={isOps}
          >
            {children}
          </BasicSearchProvider>
        </AdvancedSearchProvider>
      </CurrentSearchTypeContext.Provider>
    </UseSearchProviderUpdaterContext.Provider>
  );
};

const useCurrentSearchType = () => useContext(CurrentSearchTypeContext);
const useSearchProviderUpdater = () => useContext(UseSearchProviderUpdaterContext);

export { UseEarningsSearchProvider, useCurrentSearchType, useSearchProviderUpdater };
