const create = (text: string) => {
  const canvas = document.createElement('canvas');
  canvas.width = 255;
  canvas.height = 166;

  const context = canvas.getContext('2d');
  if (!context) {
    return '';
  }
  context.textAlign = 'left';
  context.textBaseline = 'bottom';
  context.globalAlpha = 0.2;
  context.font = '14px Microsoft Yahei';
  context.rotate(-0.26);
  context.fillStyle = '#000';
  context.fillText(text, 128, 83);

  return context.canvas.toDataURL();
};

const Watermark = () => {
  if (process.env.REACT_APP_CLOUD_ENVIRONMENT !== 'prod') {
    document.body.style.setProperty('background-image', `url(${create('NON PROD')})`, 'important');
  }
  return <div />;
};

export default Watermark;
