import React, { useEffect, useState } from 'react';
import Link from '../../shared/models/Link';
import DataCard from './DataCard';
import LoadingOverlay from '../../shared/components/Loading/LoadingOverlay';
import disbursementsApi from '../../api/disbursements-api';
import './DataCard.css';
import CancelablePromise from '../../util/CancelablePromise';
import Alert from '../../shared/components/Alert/Alert';
import ApiError from '../../api/ApiError';

interface DataCardContainerProps {
  totalAmountsLink: Link | undefined;
}

export type TotalAmounts = {
  readonly totalEarnedAmount: number;
  readonly totalCreditAmount: number;
  readonly totalDebitAmount: number;
};
const DataCardContainer: React.FC<DataCardContainerProps> = (props: DataCardContainerProps) => {
  const { totalAmountsLink } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totals, setTotals] = useState<TotalAmounts>();
  const [totalsPromise, setTotalsPromise] = useState<CancelablePromise<TotalAmounts>>();
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);

  useEffect(() => {
    if (totalsPromise) {
      totalsPromise.cancel();
    }

    const getAmounts = async () => {
      if (totalAmountsLink) {
        setApiError(undefined);
        setIsLoading(true);

        const promise = disbursementsApi.getTotalAmountsByLinkWithPromise(totalAmountsLink);
        setTotalsPromise(promise);

        promise.promise
          .then((totalAmounts) => {
            setTotals({ ...totalAmounts });
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            setApiError(error as ApiError);
          });
      }
    };

    getAmounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmountsLink]);
  return (
    <div id="DataCardContainer">
      {apiError && (
        <div className="no-print">
          <div className="util-margin-50">
            <Alert severity="danger" message="Unable to load disbursement summary." />
          </div>
        </div>
      )}
      <LoadingOverlay isActive={isLoading}>
        <div className="data-card-container">
          <div className="data-card-item">
            <DataCard label="Credits" amount={totals?.totalCreditAmount} />
          </div>
          <div className="data-card-item">
            <DataCard label="Debits" amount={totals?.totalDebitAmount} />
          </div>
          <div className="data-card-item">
            <DataCard label="Earnings" amount={totals?.totalEarnedAmount} />
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};
export default DataCardContainer;
