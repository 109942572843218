import { queryCache, useMutation } from 'react-query';
import { deleteById } from '../../api/saved-search-api';

const deleteHelper = async (id: string) => {
  await deleteById(id);
};

export default function useDeleteSavedSearch() {
  return useMutation(deleteHelper, {
    throwOnError: true,
    onSuccess: () => {
      queryCache.invalidateQueries(['saved-searches']);
    },
  });
}
