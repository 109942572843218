import './Loading.css';

const LoadingPage = () => (
  <span className="text-center">
    <h2>Hang on a moment…</h2>
    <p className="text-center">
      <i className="fa fa-spinner fa-spin loading-spinner text-primary" />
    </p>
  </span>
);

export default LoadingPage;
