import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import React from 'react';
import User from './shared/models/User';
import ViewNotifications from './notifications/view/ViewNotifications';
import Link from './shared/models/Link';
import NewNotification from './notifications/create/NewNotification';
import EditNotification from './notifications/edit/EditNotification';
import ViewSecurityProfiles from './security-profiles/ViewSecurityProfiles';

interface AdminRouteProps extends RouteProps {
  user: User;
  securityProfilesLink: Link;
  notificationsLink: Link;
  saveFirmContactLink: Link;
  saveFirmDelegateLink: Link;
}

const Operations: React.FC<AdminRouteProps> = ({
  user,
  securityProfilesLink,
  notificationsLink,
  saveFirmContactLink,
  saveFirmDelegateLink,
}: AdminRouteProps) => {
  if (!user.isOps) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container">
      <Switch>
        <Route exact path="/operations/security-lookup">
          <ViewSecurityProfiles securityProfilesLink={securityProfilesLink} />
        </Route>
        <Route exact path="/operations/notifications">
          <ViewNotifications notificationsLink={{ href: `${notificationsLink.href}/search` }} />
        </Route>
        <Route path="/operations/notifications/new">
          <NewNotification notificationsLink={notificationsLink} />
        </Route>
        <Route path="/operations/notifications/:id/edit">
          <EditNotification notificationsLink={notificationsLink} />
        </Route>
      </Switch>
    </div>
  );
};
export default Operations;
