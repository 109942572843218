import isEqual from 'lodash.isequal';
import { createContext, useContext, useState } from 'react';
import Authorization from '../../security-profiles/models/Authorization';
import { sanitizeObject } from '../../util/sanitizeObject';
import BasicSearchCriteria from '../models/BasicSearchCriteria';
import BasicSearchOption from '../models/BasicSearchOption';

interface Props {
  children?: React.ReactNode;
  searchOptions: BasicSearchOption[];
  authorizations?: Authorization[];
  isOps?: boolean;
}

const BasicSearchCriteriaContext = createContext<BasicSearchCriteria | undefined>(undefined);

type UpdateFunction = (searchCriteria: BasicSearchCriteria) => void;
const SESSION_KEY = 'basicSearchCriteria';

const BasicSearchCriteriaUpdaterContext = createContext<UpdateFunction>(() => {});
const BasicSearchOptionsContext = createContext<BasicSearchOption[]>([]);

const BasicSearchProvider = (props: Props) => {
  const { children, searchOptions, authorizations, isOps } = props;

  const [state, setState] = useState<BasicSearchCriteria>(() => {
    const persistedString = sessionStorage.getItem(SESSION_KEY);
    if (persistedString) {
      const { option, enterpriseId } = JSON.parse(persistedString);
      const hasPersistedOption = searchOptions.find(({ key }) => key === option) != null;
      const hasPersistedEnterpriseId = isOps || authorizations?.find(({ subject }) => subject === enterpriseId) != null;
      return {
        option: hasPersistedOption ? option : '',
        enterpriseId: hasPersistedEnterpriseId ? enterpriseId : '',
      };
    }
    return {
      option: searchOptions[0]?.key ?? '',
      enterpriseId: authorizations?.[0]?.subject ?? '',
    };
  });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const update = (newSearchCriteria: BasicSearchCriteria) => {
    setState((previousState) => {
      sanitizeObject(previousState);
      sanitizeObject(newSearchCriteria);
      if (!isEqual(previousState, newSearchCriteria)) {
        sessionStorage.setItem(SESSION_KEY, JSON.stringify(newSearchCriteria));
        return newSearchCriteria;
      }
      return previousState;
    });
  };

  return (
    <BasicSearchCriteriaContext.Provider value={state}>
      <BasicSearchOptionsContext.Provider value={searchOptions}>
        <BasicSearchCriteriaUpdaterContext.Provider value={update}>
          {children}
        </BasicSearchCriteriaUpdaterContext.Provider>
      </BasicSearchOptionsContext.Provider>
    </BasicSearchCriteriaContext.Provider>
  );
};

const useBasicSearchCriteria = (): BasicSearchCriteria | undefined => {
  const searchCriteria = useContext(BasicSearchCriteriaContext);
  return searchCriteria;
};

const useBasicSearchCriteriaUpdater = (): UpdateFunction => {
  const updateSearchCriteria = useContext(BasicSearchCriteriaUpdaterContext);
  if (updateSearchCriteria === undefined) {
    throw new Error('useSearchCriteriaUpdater must be used within Search Criteria Updater Context');
  }

  return updateSearchCriteria;
};

const useBasicSearchOptions = (): BasicSearchOption[] => {
  const searchOptions = useContext<BasicSearchOption[]>(BasicSearchOptionsContext);
  if (searchOptions === undefined) {
    throw new Error('useSearchOptions must be used within Search Option Context');
  }

  return searchOptions;
};

export { BasicSearchProvider, useBasicSearchCriteria, useBasicSearchCriteriaUpdater, useBasicSearchOptions };
