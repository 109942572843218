import { useRef } from 'react';
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import Column from '../../../models/Column';
import { ItemType } from '../Table';

export interface DraggableThProps {
  index: number;
  column: Column;
  moveHeader: (dragIndex: number, hoverIndex: number) => void;
  sortHandler: (c: Column) => void;
  onDrop: () => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const sortClassName = (column: Column) => {
  if (!column.sortable) {
    return '';
  }
  if (!column.isSorted) {
    return 'sortable';
  }
  return `sort-${column.sortDirection}`;
};

const DraggableTh = (props: DraggableThProps) => {
  const { index, column, moveHeader, sortHandler, onDrop } = props;

  const sortByColumn = (columnToSort: Column): (() => void) => {
    if (columnToSort.sortable) {
      return () => {
        sortHandler(columnToSort);
      };
    }
    return () => {
      // empty
    };
  };

  const ref = useRef<HTMLTableHeaderCellElement>(null);
  const [, drop] = useDrop({
    accept: ItemType.TH,
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRectangle = ref.current.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRectangle.right - hoverBoundingRectangle.left) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRectangle.left;

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      moveHeader(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: () => {
      onDrop();
    },
  });

  const [, drag] = useDrag({
    item: {
      type: ItemType.TH,
      id: column.id,
      index,
    },
    collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() }),
  });

  drag(drop(ref));
  return (
    <th
      ref={ref}
      role="columnheader"
      key={column.id}
      className={sortClassName(column)}
      title={column.tooltipText}
      onClick={sortByColumn(column)}
    >
      {column.header}
    </th>
  );
};

export default DraggableTh;
