import Watermark from '../../watermark/Watermark/Watermark';
import './Navbar.css';

interface Props {
  children?: React.ReactNode;
}

const NavLayout = ({ children }: Props) => (
  <>
    <Watermark />
    <nav className="navbar navbar-default">
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#hub-navbar-right"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <a className="navbar-principal-brand" href={`${process.env.PUBLIC_URL}/index.html`}>
            Principal
          </a>
        </div>
        <div className="collapse navbar-collapse" id="hub-navbar-right">
          <ul className="nav navbar-right navbar-nav">{children}</ul>
        </div>
      </div>
    </nav>
  </>
);

export default NavLayout;
