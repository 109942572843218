import HubFetch from './hub-fetch';
import ApiError from './ApiError';
import SecurityProfileSearchRequest from '../security-profiles/models/SecurityProfileSearchRequest';
import SecurityProfile from '../security-profiles/models/SecurityProfile';

// eslint-disable-next-line import/prefer-default-export
export async function getSecurityProfile(
  url: string,
  searchRequest: SecurityProfileSearchRequest,
): Promise<SecurityProfile[]> {
  const method = 'POST';
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  const body = JSON.stringify(searchRequest);
  const init: RequestInit = { method, headers, body };

  try {
    const collectionModel = JSON.parse(await HubFetch.hubFetch(url, init));
    if (!collectionModel?._embedded?.securityProfiles) {
      return [];
    }
    return collectionModel._embedded.securityProfiles;
  } catch (e) {
    throw new ApiError((e as ApiError).message, (e as ApiError).status, (e as ApiError).errors);
  }
}
