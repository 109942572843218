import { Tab, Tabs } from 'react-bootstrap';
import React from 'react';
import Search from './search/Search/Search/Search';
import EarningsViewContainer from './view/EarningsViewContainer';
import HubView from './shared/models/HubView';
import Notifications from './notifications/Notifications';
import AccessRemovedAlert from './error/AccessRemovedAlert/AccessRemovedAlert';
import { UseEarningsSearchProvider } from './search/hooks/useEarningsSearchProvider';
import AdjustmentsViewContainer from './view/AdjustmentsViewContainer';
import { AdvancedSearchProvider } from './search/hooks/useAdvancedSearchCriteria';
import AdvancedSearchInputGroup from './search/Search/SearchInput/AdvancedSearchInputGroup';

export interface HubProps {
  view: HubView;
}

const Hub: React.FC<HubProps> = ({ view }: HubProps) => (
  <div id="Hub" className="row">
    <div className="col-xs-12">
      <Notifications notificationHref={view._links.notifications.href} />
      <Tabs defaultActiveKey="earnings" id="tabs" className="util-padding-left-10">
        <Tab eventKey="earnings" title="Earnings">
          <UseEarningsSearchProvider
            advancedSearchOptions={view.earningsView.advancedSearchOptions}
            basicSearchOptions={view.earningsView.basicSearchOptions}
            securityProfileAuthorizations={view.user.securityProfileAuthorizations}
            isOps={view.user.isOps}
          >
            <Search isOps={view.user.isOps} securityProfileAuthorizations={view.user.securityProfileAuthorizations} />
            <AccessRemovedAlert hasAccessRemoved={view.user.hasAccessRemoved} />
            <EarningsViewContainer
              advancedSearchLink={view.earningsView._links.disbursements}
              basicDisbursementsSearchLink={view.earningsView._links.basicDisbursementsSearch}
              basicEarningsSearchLink={view.earningsView._links.basicEarningsSearch}
              getEarningsAsCsvLink={view.earningsView._links.getEarningsAsCsv}
              commissionTypeFilterOptionsLink={view.earningsView._links.commissionTypeFilterOptions}
              compensationTypeFilterOptionsLink={view.earningsView._links.compensationTypeFilterOptions}
              compensationEventTypeFilterOptionsLink={view.earningsView._links.compensationEventTypeFilterOptions}
              premiumTypeFilterOptionsLink={view.earningsView._links.premiumTypeFilterOptions}
              preferencesLink={view.user._links.preferences}
              columnDefinitions={view.earningsView.columnDefinitions}
              defaultColumns={view.earningsView.defaultColumns}
              isOps={view.user.isOps}
            />
          </UseEarningsSearchProvider>
        </Tab>
        <Tab eventKey="adjustments" title="Adjustments">
          <AdvancedSearchProvider
            searchOptions={view.adjustmentsView.advancedSearchOptions}
            sessionKey="adjustmentsAdvancedSearchCriteria"
          >
            <div className="util-margin-horz-60 util-margin-top-30">
              <div style={{ margin: 'auto 0' }}>
                <AdvancedSearchInputGroup />
              </div>
            </div>
            <AdjustmentsViewContainer
              disbursementsLink={view.adjustmentsView._links.disbursements}
              columnDefinitions={view.adjustmentsView.columnDefinitions}
              isOps={view.user.isOps}
            />
          </AdvancedSearchProvider>
        </Tab>
      </Tabs>
    </div>
  </div>
);

export default Hub;
