import HubFetch from './hub-fetch';
import HubView from '../shared/models/HubView';

const baseUrl = process.env.REACT_APP_COMPENSATION_HUB_API;

async function get(): Promise<HubView> {
  const response = await HubFetch.hubFetch(`${baseUrl}/view`);
  return JSON.parse(response);
}

export default {
  get,
  baseUrl,
};
