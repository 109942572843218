import React, { useEffect, useState } from 'react';
import InputLabel from '../InputLabel/InputLabel';

export interface Props {
  inputId: string;
  labelText?: string;
  onChange: (value: string) => void;
  maxCharacters?: number;
  required?: boolean;
  placeholder?: string;
  value?: string;
  invalid?: boolean;
  feedback?: string;
  disabled?: boolean;
}

const TextareaFormGroup: React.FC<Props> = (props) => {
  const { inputId, labelText, onChange, maxCharacters, required, placeholder, value, invalid, feedback, disabled } =
    props;
  const [valueState, setValueState] = useState<string>(value || '');
  const [charactersRemaining, setCharactersRemaining] = useState<number | undefined>(
    maxCharacters && maxCharacters - valueState.length,
  );
  let formGroupClassName = 'form-group util-margin-bottom-50';
  if (invalid) {
    formGroupClassName = `${formGroupClassName} has-error`;
  }

  useEffect(() => {
    setValueState(value || '');
  }, [setValueState, value]);

  useEffect(() => {
    setCharactersRemaining(maxCharacters && maxCharacters - valueState.length);
  }, [setCharactersRemaining, maxCharacters, valueState]);

  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputText = event.target.value;
    onChange(inputText);
    setCharactersRemaining(maxCharacters && maxCharacters - inputText.length);
  };

  return (
    <div className={formGroupClassName}>
      {labelText !== undefined && <InputLabel htmlFor={inputId} labelText={labelText} isRequired={required} />}
      <textarea
        id={inputId}
        className="form-control"
        disabled={disabled}
        onChange={onInputChange}
        maxLength={maxCharacters}
        placeholder={placeholder}
        rows={3}
        required={required}
        value={value}
      />
      {charactersRemaining !== undefined && (
        <p className="pull-right">{`Characters remaining: ${charactersRemaining}`}</p>
      )}
      {feedback && <p className="pull-left has-error-message help-block">{feedback}</p>}
    </div>
  );
};
export default TextareaFormGroup;
