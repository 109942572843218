import { Link } from 'react-router-dom';
import User from '../../shared/models/User';
import './Navbar.css';
import { FeedbackModalId } from '../../feedback/Feedback/Feedback';
import LocationAwareNavItem from './LocationAwareNavItem';
import NavbarLink from '../../shared/models/Link';
import NavLayout from './NavLayout';

interface Props {
  user?: User;
  feedback?: NavbarLink;
  loggedIn?: boolean;
  logout: () => void;
  login: () => void;
}

const Navbar = (props: Props) => {
  const { user, feedback, loggedIn, logout } = props;

  const homepageLink =
    'https://advisors.principal.com/wps/myportal/advisor/resource-center/compensation/compensation-hub';
  const taxReportingLink =
    'https://advisors.principal.com/wps/myportal/advisor/resource-center/compensation/overview/tax-reporting-overview';

  return (
    <NavLayout>
      {user?.isOps && loggedIn && (
        <>
          <LocationAwareNavItem includesString="securityProfiles">
            <Link to="/operations/security-lookup">Security Lookup</Link>
          </LocationAwareNavItem>
          <LocationAwareNavItem includesString="notifications">
            <Link to="/operations/notifications">Notifications</Link>
          </LocationAwareNavItem>
        </>
      )}
      {!user?.isOps && loggedIn && (
        <li className="dropdown">
          <a href="# " className="dropdown-toggle" data-toggle="dropdown">
            Resources &nbsp;
            <i className="fa fa-angle-down" />
          </a>
          <ul className="dropdown-menu" role="menu">
            {feedback?.href && (
              <li>
                <a href="# " data-toggle="modal" data-target={`#${FeedbackModalId}`}>
                  Feedback
                </a>
              </li>
            )}
            <li>
              <a href={homepageLink}>Homepage</a>
            </li>
            <li>
              <a href={taxReportingLink}>Tax Reporting Overview</a>
            </li>
          </ul>
        </li>
      )}
      {loggedIn && (
        <li className="dropdown">
          <a href="# " className="dropdown-toggle" data-toggle="dropdown">
            {user?.name}
            &nbsp;
            <i className="fa fa-angle-down" />
          </a>
          <ul className="dropdown-menu" role="menu">
            {user?.isOps && feedback?.href && (
              <li>
                <a href="# " data-toggle="modal" data-target={`#${FeedbackModalId}`}>
                  Feedback
                </a>
              </li>
            )}
            <li>
              <Link to="/" onClick={logout}>
                Log out
              </Link>
            </li>
          </ul>
        </li>
      )}
    </NavLayout>
  );
};

export default Navbar;
