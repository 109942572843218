const PLACEHOLDER = '---';

const isoToDisplayDate = (isoDateTime: string | undefined): string => {
  if (isoDateTime === undefined) {
    return PLACEHOLDER;
  }
  const isoDate = isoDateTime.includes('T') ? isoDateTime.substring(0, isoDateTime.indexOf('T')) : isoDateTime;
  const [year, month, day] = isoDate.split('-');
  return `${month}/${day}/${year}`;
};

const isoToFilename = (isoDateTime: string): string => {
  const isoDate = isoDateTime.includes('T') ? isoDateTime.substring(0, isoDateTime.indexOf('T')) : isoDateTime;
  const [year, month, day] = isoDate.split('-');
  return `${year}-${month}-${day}`;
};

const isoToLongDate = (isoDate: string | undefined, longMonth?: boolean): string => {
  let returnString = '';
  if (isoDate) {
    const dateTokens = isoDate.split('-');
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const date = new Date(Number(dateTokens[0]), Number(dateTokens[1]) - 1, Number(dateTokens[2]));
    const labelTokens = date.toString().split(' ');

    if (longMonth) {
      returnString = `${monthNames[date.getMonth()]} ${labelTokens[2]}, ${labelTokens[3]}`;
    } else {
      returnString = `${labelTokens[1]} ${labelTokens[2]} ${labelTokens[3]}`;
    }
  }
  return returnString;
};

const numberToFixedPercent = (decimal: number | undefined): string => {
  if (decimal === undefined) {
    return PLACEHOLDER;
  }
  return `${(decimal * 100).toFixed(2)}%`;
};

const commaSeparatedNumber = /(\d)(?=(\d{3})+(?!\d))/g;

const numberToUSD = (number: number | undefined): string => {
  if (number === undefined) {
    return '-.--';
  }
  return number.toFixed(2).replace(commaSeparatedNumber, '$1,');
};

const lastFourDigitsOfTaxIdToMaskedTaxId = (lastFourDigitsOfTaxId: string | undefined): string =>
  !lastFourDigitsOfTaxId ? 'N/A' : `XXXXX${lastFourDigitsOfTaxId}`;

const valueToString = (value: never): string => (value === undefined || value === null ? '' : String(value));

const stringOrPlaceholder = (value?: string): string => (value === undefined || value === null ? PLACEHOLDER : value);

/* eslint-disable @typescript-eslint/no-explicit-any */
export const mapToFormatter: Record<string, any> = {
  date: isoToDisplayDate,
  percent: numberToFixedPercent,
  currency: numberToUSD,
  string: valueToString,
  optionalString: stringOrPlaceholder,
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export default {
  isoToDisplayDate,
  isoToFilename,
  isoToLongDate,
  numberToFixedPercent,
  numberToUSD,
  lastFourDigitsOfTaxIdToMaskedTaxId,
  stringOrPlaceholder,
  valueToString,
};
