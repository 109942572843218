interface CheckboxProps {
  id: string;
  label: string;
  checked?: boolean;
  onChange?: (isChecked: boolean) => void;
}

type HtmlLabelPropsWithoutOnChange = Omit<React.HTMLProps<HTMLLabelElement>, 'onChange'>;
type Props = CheckboxProps & HtmlLabelPropsWithoutOnChange;

const Checkbox = (props: Props) => {
  const { id, label, checked, onChange, title } = props;

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      const isChecked = event.currentTarget.checked;
      onChange(isChecked);
    }
  };

  return (
    <div className="checkbox styled-checkbox">
      <input type="checkbox" id={id} checked={!!checked} onChange={onCheckboxChange} />
      <label htmlFor={id} title={title}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
