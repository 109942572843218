import 'draft-js/dist/Draft.css';
import './RichContentEditable.css';
import React from 'react';
import InputLabel from '../InputLabel/InputLabel';
import RichContentEditable, { Message } from './RichContentEditable';

interface Props {
  inputId: string;
  labelText?: string;
  onChange: (value: Message) => void;
  required?: boolean;
  placeholder?: string;
  value: Message;
  invalid?: boolean;
  feedback?: string;
  disabled?: boolean;
}

const RichContentEditableFormGroup: React.FC<Props> = (props) => {
  const { onChange, labelText, invalid, inputId, required, feedback, value, placeholder, disabled } = props;

  let formGroupClassName = 'form-group util-margin-bottom-50';
  if (invalid) {
    formGroupClassName = `${formGroupClassName} has-error`;
  }

  return (
    <div role="group" className={formGroupClassName}>
      {labelText !== undefined && <InputLabel htmlFor={inputId} labelText={labelText} isRequired={required} />}
      <RichContentEditable
        inputId={inputId}
        onChange={onChange}
        placeholder={placeholder}
        message={value}
        disabled={disabled}
      />
      {feedback && <p className="pull-left has-error-message help-block">{feedback}</p>}
    </div>
  );
};

export default RichContentEditableFormGroup;
