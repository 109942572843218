import { Form } from 'react-bootstrap';
import React, { FormEventHandler, useEffect, useState } from 'react';
import Authorization from '../../../security-profiles/models/Authorization';
import './SearchInputGroup.css';
import BasicSearchOption, {
  ALL_BY_ACCOUNT_NUMBER,
  ALL_BY_PAID_DATE,
  MY_PAY_BY_DATE_RANGE,
} from '../../models/BasicSearchOption';
import BasicSearchOptions from './BasicSearchOptions';
import {
  useBasicSearchCriteria,
  useBasicSearchCriteriaUpdater,
  useBasicSearchOptions,
} from '../../hooks/useBasicSearchCriteria';
import BasicSearchCriteria from '../../models/BasicSearchCriteria';

interface Props {
  isOps?: boolean;
  securityProfileAuthorizations?: Authorization[];
}

const hasName = (auth: Authorization) => auth.name !== 'MISSING NAME';

const SearchInputGroup = (props: Props) => {
  const { isOps, securityProfileAuthorizations = [] } = props;

  const basicSearchOptions = useBasicSearchOptions();
  const basicSearchCriteriaUpdater = useBasicSearchCriteriaUpdater();
  const basicSearchCriteria = useBasicSearchCriteria();

  const filteredAuthorizations = securityProfileAuthorizations.filter(hasName).filter((auth) => auth.active);

  const [currentAuthorization, setCurrentAuthorization] = useState<Authorization>(
    filteredAuthorizations[0] ? filteredAuthorizations[0] : ({} as Authorization),
  );
  const [currentOption, setCurrentOption] = useState<BasicSearchOption>({
    key: basicSearchCriteria?.option,
  } as BasicSearchOption);
  const [enterpriseIdInput, setEnterpriseIdInput] = useState<string>(basicSearchCriteria?.enterpriseId ?? '');
  const [isEnterpriseIdValid, setIsEnterpriseIdValid] = useState<boolean>(true);
  const [paidDate, setPaidDate] = useState<string>(basicSearchCriteria?.paidDate ?? '');
  const [startDate, setStartDate] = useState<string>(basicSearchCriteria?.startDate ?? '');
  const [endDate, setEndDate] = useState<string>(basicSearchCriteria?.endDate ?? '');
  const [isPaidDateValid, setIsPaidDateValid] = useState<boolean>(true);
  const [isStartDateValid, setIsStartDateValid] = useState<boolean>(true);
  const [isEndDateValid, setIsEndDateValid] = useState<boolean>(true);
  const [accountNumber, setAccountNumber] = useState<string>(basicSearchCriteria?.accountNumber ?? '');
  const [isAccountNumberValid, setIsAccountNumberValid] = useState<boolean>(true);

  const onEnterpriseIdInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnterpriseIdInput(event.target.value);
  };

  const onPaidDateInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaidDate(event.target.value);
  };

  const onStartDateInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value);
  };

  const onEndDateInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  const onAccountNumberInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountNumber(event.target.value);
  };

  const applySearch: FormEventHandler<Form> = async (event) => {
    event.preventDefault();
    if (currentOption.key && validateBasicSearchForm()) {
      const criteria = { option: currentOption.key, enterpriseId: enterpriseIdInput } as BasicSearchCriteria;
      if (paidDate) {
        criteria.paidDate = paidDate;
      }
      if (startDate && endDate) {
        criteria.startDate = startDate;
        criteria.endDate = endDate;
      }
      if (accountNumber) {
        criteria.accountNumber = accountNumber;
      }
      basicSearchCriteriaUpdater(criteria);
    }
  };

  const validateBasicSearchForm = (): boolean => {
    setIsEnterpriseIdValid(true);
    setIsPaidDateValid(true);
    setIsStartDateValid(true);
    setIsEndDateValid(true);
    setIsAccountNumberValid(true);
    let formIsValid = true;

    if (!enterpriseIdInput) {
      setIsEnterpriseIdValid(false);
      formIsValid = false;
    }

    if (currentOption && currentOption.key === 'ALL_BY_PAID_DATE' && !paidDate) {
      setIsPaidDateValid(false);
      formIsValid = false;
    }

    if (currentOption && currentOption.key === 'MY_PAY_BY_DATE_RANGE' && !startDate && !endDate) {
      setIsStartDateValid(false);
      setIsEndDateValid(false);
      formIsValid = false;
    } else if (startDate > endDate) {
      setIsStartDateValid(false);
      setIsEndDateValid(false);
      formIsValid = false;
    }

    if (currentOption && currentOption.key === 'ALL_BY_ACCOUNT_NUMBER' && !accountNumber) {
      setIsAccountNumberValid(false);
      formIsValid = false;
    }
    return formIsValid;
  };

  useEffect(() => {
    if (!isOps && currentOption.key && currentAuthorization.name) {
      const criteria = {
        option: currentOption.key,
        enterpriseId: currentAuthorization.subject,
      } as BasicSearchCriteria;
      if (paidDate && currentOption.key === 'ALL_BY_PAID_DATE') {
        criteria.paidDate = paidDate;
      } else if (startDate && endDate && currentOption.key === 'MY_PAY_BY_DATE_RANGE') {
        criteria.startDate = startDate;
        criteria.endDate = endDate;
      } else {
        delete criteria.paidDate;
        delete criteria.startDate;
        delete criteria.endDate;
      }
      basicSearchCriteriaUpdater(criteria);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOption, currentAuthorization, isOps, basicSearchCriteriaUpdater]);

  useEffect(() => {
    if (isOps && currentOption.key && !!enterpriseIdInput) {
      const criteria = { option: currentOption.key, enterpriseId: enterpriseIdInput } as BasicSearchCriteria;
      if (paidDate && currentOption.key === 'ALL_BY_PAID_DATE') {
        criteria.paidDate = paidDate;
      } else if (startDate && endDate && currentOption.key === 'MY_PAY_BY_DATE_RANGE') {
        criteria.startDate = startDate;
        criteria.endDate = endDate;
      } else if (accountNumber && currentOption.key === 'ALL_BY_ACCOUNT_NUMBER') {
        criteria.accountNumber = accountNumber;
      } else {
        delete criteria.paidDate;
        delete criteria.startDate;
        delete criteria.endDate;
        delete criteria.accountNumber;
      }

      basicSearchCriteriaUpdater(criteria);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOption, isOps, basicSearchCriteriaUpdater]);

  const operationsSearchInput = (
    <div
      className={
        isEnterpriseIdValid
          ? 'util-margin-top-5 form-group basic-search-input'
          : 'util-margin-top-5 form-group basic-search-input has-error'
      }
    >
      <label htmlFor="basicSearchEnterpriseIdText" className="is-required">
        For enterprise id:
      </label>
      <input
        type="text"
        name="basicSearchEnterpriseIdText"
        className="form-control"
        value={enterpriseIdInput || ''}
        onChange={onEnterpriseIdInputChange}
      />
      <span className="has-error-message help-block"> Please enter an enterprise id</span>
    </div>
  );

  const nonOperationsSearchDropdown = (
    <div className="util-margin-top-5 form-group">
      <div>
        <label htmlFor="authorizationsDropdown" className="is-required">
          For:{' '}
        </label>
      </div>
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-primary dropdown-toggle authorizations-btn"
          data-toggle="dropdown"
          name="authorizationsDropdown"
        >
          <span className="pull-left">{currentAuthorization.name ? currentAuthorization.name : ''}</span>
          <i className="fa fa-angle-down pull-right util-padding-left-10 util-vertical-align-middle" />
          <span className="sr-only">Toggle Dropdown</span>
        </button>
        <ul className="dropdown-menu dropdown-menu-left" role="menu">
          {filteredAuthorizations.map((auth) => (
            <li key={auth.subject} value={auth.subject}>
              <a href="# " onClick={() => setCurrentAuthorization(auth)}>
                {`${auth.officeId ? `${auth.officeId} ⚊ ${auth.name}` : auth.name}`}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  const paidDatePicker = (
    <div
      className={
        isPaidDateValid
          ? 'util-margin-top-5 form-group basic-search-input'
          : 'util-margin-top-5 form-group basic-search-input has-error'
      }
    >
      <label htmlFor="PaidDatePicker" className="is-required">
        Paid Date:{' '}
      </label>
      <input
        type="date"
        id="PaidDatePicker"
        className="form-control"
        value={paidDate || ''}
        onChange={onPaidDateInputChange}
      />
      <span className="has-error-message help-block"> Please enter a date</span>
    </div>
  );

  const startDatePicker = (
    <div
      className={
        isStartDateValid
          ? 'util-margin-top-5 form-group basic-search-input'
          : 'util-margin-top-5 form-group basic-search-input has-error'
      }
    >
      <label htmlFor="StartDatePicker" className="is-required">
        Start Date:{' '}
      </label>
      <input
        type="date"
        id="StartDatePicker"
        className="form-control"
        value={startDate || ''}
        onChange={onStartDateInputChange}
      />
      <span className="has-error-message help-block"> Please enter a valid start date</span>
    </div>
  );

  const endDatePicker = (
    <div
      className={
        isEndDateValid
          ? 'util-margin-top-5 form-group basic-search-input'
          : 'util-margin-top-5 form-group basic-search-input has-error'
      }
    >
      <label htmlFor="EndDatePicker" className="is-required">
        End Date:{' '}
      </label>
      <input
        type="date"
        id="EndDatePicker"
        className="form-control"
        value={endDate || ''}
        onChange={onEndDateInputChange}
      />
      <span className="has-error-message help-block"> Please enter a valid end date</span>
    </div>
  );

  const AccountNumberInput = (
    <div
      className={
        isAccountNumberValid
          ? 'util-margin-top-5 form-group basic-search-input'
          : 'util-margin-top-5 form-group basic-search-input has-error'
      }
    >
      <label htmlFor="accountNumberInputText" className="is-required">
        For account number:{' '}
      </label>
      <input
        type="text"
        name="accountNumberInputText"
        className="form-control"
        value={accountNumber || ''}
        onChange={onAccountNumberInputChange}
      />
      <span className="has-error-message help-block"> Please enter a valid account number</span>
    </div>
  );

  return (
    <div className="row">
      <div className="basic-search-form-container">
        <Form onSubmit={applySearch} className="basic-search-form">
          <div className="form-group">
            <small className="is-required-header pull-right">Required</small>
          </div>
          <BasicSearchOptions
            basicSearchOptions={basicSearchOptions}
            currentOption={currentOption}
            setCurrentOption={setCurrentOption}
          />
          {currentOption.key === ALL_BY_PAID_DATE && paidDatePicker}
          {currentOption.key === MY_PAY_BY_DATE_RANGE && startDatePicker}
          {currentOption.key === MY_PAY_BY_DATE_RANGE && endDatePicker}
          {currentOption.key === ALL_BY_ACCOUNT_NUMBER && AccountNumberInput}
          {isOps ? operationsSearchInput : nonOperationsSearchDropdown}
          <button className="btn btn-primary" type="submit" data-gtm="call-to-action">
            Search
          </button>
        </Form>
      </div>
    </div>
  );
};

export default SearchInputGroup;
