import React, { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import DisbursementPicker from '../disbursements/DisbursementPicker/DisbursementPicker';
import DisbursementApi from '../api/disbursements-api';
import EarningsApi from '../api/earnings-api';
import EarningsTable from '../earnings/table/EarningsTable';
import DisbursementSummary from '../disbursements/models/DisbursementSummary';
import Link from '../shared/models/Link';
import Disbursement from '../disbursements/models/Disbursement';
import Column from '../shared/models/Column';
import { useAdvancedSearchCriteria } from '../search/hooks/useAdvancedSearchCriteria';
import FilterOption from '../earnings/models/FilterOption';
import PayDetails, { CompensationType } from '../disbursements/PayDetails/PayDetails';
import { UserPreferencesProvider } from '../user-preferences/UserPreferencesProvider';
import ApiError from '../api/ApiError';
import Alert, { AlertProps } from '../shared/components/Alert/Alert';
import { useBasicSearchCriteria } from '../search/hooks/useBasicSearchCriteria';
import { useCurrentSearchType } from '../search/hooks/useEarningsSearchProvider';
import AdvancedSearchCriteria from '../search/models/AdvancedSearchCriteria';
import BasicSearchCriteria from '../search/models/BasicSearchCriteria';
import LoadingPage from '../shared/components/Loading/LoadingPage';
import DownloadButton from '../disbursements/DownloadButton/DownloadButton';
import DataCardContainer from '../disbursements/DataCard/DataCardContainer';

interface Props {
  preferencesLink: Link;
  advancedSearchLink: Link;
  basicDisbursementsSearchLink: Link;
  basicEarningsSearchLink: Link;
  getEarningsAsCsvLink: Link;
  commissionTypeFilterOptionsLink: Link;
  compensationTypeFilterOptionsLink: Link;
  compensationEventTypeFilterOptionsLink: Link;
  premiumTypeFilterOptionsLink: Link;
  columnDefinitions: Column[];
  defaultColumns: string[];
  isOps?: boolean;
}

const EarningsViewContainer = (props: Props) => {
  const {
    preferencesLink,
    advancedSearchLink,
    basicDisbursementsSearchLink,
    basicEarningsSearchLink,
    getEarningsAsCsvLink,
    commissionTypeFilterOptionsLink,
    compensationTypeFilterOptionsLink,
    compensationEventTypeFilterOptionsLink,
    premiumTypeFilterOptionsLink,
    columnDefinitions,
    defaultColumns,
    isOps,
  } = props;

  const [disbursements, setDisbursements] = useState<DisbursementSummary[]>([]);
  const [currentDisbursement, setCurrentDisbursement] = useState<Disbursement>();
  const [commissionTypeFilterOptions, setCommissionTypeFilterOptions] = useState<FilterOption[]>([]);
  const [compensationTypeFilterOptions, setCompensationTypeFilterOptions] = useState<FilterOption[]>([]);
  const [compensationEventTypeFilterOptions, setCompensationEventTypeFilterOptions] = useState<FilterOption[]>([]);
  const [premiumTypeFilterOptions, setPremiumTypeFilterOptions] = useState<FilterOption[]>([]);
  const [alert, setAlert] = useState<AlertProps>({ message: 'No disbursements here.', severity: 'info' });
  const advancedSearchCriteria = useAdvancedSearchCriteria();
  const basicSearchCriteria = useBasicSearchCriteria();
  const currentSearchType = useCurrentSearchType();
  const [initialPageEarningsLink, setInitialPageEarningsLink] = useState<Link>();
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDisbursementDetail, setIsLoadingDisbursementDetail] = useState<boolean>(false);
  const [isDisbursementView, setIsDisbursementView] = useState<boolean>(true);

  const callback = useCallback(
    async (searchCriteria: AdvancedSearchCriteria | BasicSearchCriteria, searchLink: Link, useEarningsView = false) => {
      if (useEarningsView) {
        const handleEarningsView = async () => {
          setIsDisbursementView(false);
          setIsLoading(true);
          setApiError(undefined);
          setCompensationEventTypeFilterOptions(
            await EarningsApi.getFilterOptions(compensationEventTypeFilterOptionsLink),
          );
          setCommissionTypeFilterOptions(await EarningsApi.getFilterOptions(commissionTypeFilterOptionsLink));
          setCompensationTypeFilterOptions(await EarningsApi.getFilterOptions(compensationTypeFilterOptionsLink));
          setPremiumTypeFilterOptions(await EarningsApi.getFilterOptions(premiumTypeFilterOptionsLink));
          setIsLoading(false);
        };

        await handleEarningsView();
      } else {
        let newView;
        try {
          setIsDisbursementView(true);
          setIsLoading(true);
          newView = await DisbursementApi.getDisbursements(searchCriteria, searchLink.href);
          setApiError(undefined);
        } catch (e) {
          setApiError(e as ApiError);
          setIsLoading(false);
        }

        const newDisbursements = newView === undefined ? [] : newView.disbursementSummaries;
        let newCurrentDisbursement;
        if (newDisbursements.length > 0) {
          const disbursement = newDisbursements[newDisbursements.length - 1];
          newCurrentDisbursement = await DisbursementApi.getDisbursementByUrl(disbursement._links.disbursement);
          setCompensationEventTypeFilterOptions(
            await EarningsApi.getFilterOptions(compensationEventTypeFilterOptionsLink),
          );
          setCommissionTypeFilterOptions(await EarningsApi.getFilterOptions(commissionTypeFilterOptionsLink));
          setCompensationTypeFilterOptions(await EarningsApi.getFilterOptions(compensationTypeFilterOptionsLink));
          setPremiumTypeFilterOptions(await EarningsApi.getFilterOptions(premiumTypeFilterOptionsLink));
        } else if (isOps && isEmpty(searchCriteria)) {
          setAlert({ message: 'Please enter a search criteria.', severity: 'info' });
        } else {
          setAlert({ message: 'No disbursements matching search criteria.', severity: 'info' });
        }
        setCurrentDisbursement(newCurrentDisbursement);
        setDisbursements(newDisbursements);
        setIsLoading(false);
      }
    },
    [
      compensationEventTypeFilterOptionsLink,
      commissionTypeFilterOptionsLink,
      compensationTypeFilterOptionsLink,
      premiumTypeFilterOptionsLink,
      isOps,
    ],
  );

  useEffect(() => {
    const performSearch = async (): Promise<void> => {
      if (currentSearchType === 'advanced' && advancedSearchCriteria) {
        await callback(advancedSearchCriteria, advancedSearchLink);
      }
    };

    performSearch();
  }, [advancedSearchCriteria, advancedSearchLink, callback, currentSearchType]);

  useEffect(() => {
    const performSearch = async (): Promise<void> => {
      if (currentSearchType === 'basic' && basicSearchCriteria && basicSearchCriteria.enterpriseId !== '') {
        if (
          basicSearchCriteria.option === 'ALL_BY_PAID_DATE' ||
          basicSearchCriteria.option === 'MY_PAY_BY_DATE_RANGE' ||
          basicSearchCriteria?.option === 'ALL_BY_ACCOUNT_NUMBER'
        ) {
          await callback(basicSearchCriteria, basicEarningsSearchLink, true);
        } else {
          await callback(basicSearchCriteria, basicDisbursementsSearchLink);
        }
      }
    };

    performSearch();
  }, [basicSearchCriteria, basicDisbursementsSearchLink, basicEarningsSearchLink, callback, currentSearchType]);

  useEffect(() => {
    setCurrentDisbursement(undefined);
    setDisbursements([]);
    setAlert({ message: 'Please enter a search criteria.', severity: 'info' });
  }, [currentSearchType]);

  useEffect(() => {
    const earningsView =
      currentSearchType === 'basic' &&
      basicSearchCriteria &&
      (basicSearchCriteria.option === 'ALL_BY_PAID_DATE' ||
        basicSearchCriteria?.option === 'MY_PAY_BY_DATE_RANGE' ||
        basicSearchCriteria?.option === 'ALL_BY_ACCOUNT_NUMBER');
    const earningsLink = earningsView ? basicEarningsSearchLink : currentDisbursement?._links.basicSearch;

    setInitialPageEarningsLink(
      currentSearchType === 'advanced' ? currentDisbursement?._links.advancedSearch : earningsLink,
    );
  }, [currentSearchType, currentDisbursement, basicSearchCriteria, basicEarningsSearchLink]);

  const handleDisbursementClickCallback = async (disbursementLink: Link) => {
    setApiError(undefined);
    setIsLoadingDisbursementDetail(true);
    try {
      const disbursementDetail = await DisbursementApi.getDisbursementByUrl(disbursementLink);
      setCurrentDisbursement(disbursementDetail);
    } catch (err) {
      setApiError(err as ApiError);
    }
    setIsLoadingDisbursementDetail(false);
  };

  const disbursementPicker = (
    <DisbursementPicker
      disbursements={disbursements}
      handleDisbursementClickCallback={handleDisbursementClickCallback}
      alert={alert}
      isOps={isOps}
      basicSearchOption={basicSearchCriteria?.option}
      currentSearchType={currentSearchType}
    />
  );

  const disbursementPayDetails = (
    <>
      <PayDetails disbursement={currentDisbursement as Disbursement} compensationType={CompensationType.DISBURSEMENT} />
      <DataCardContainer totalAmountsLink={currentDisbursement?._links?.totalAmounts} />
    </>
  );
  const hasCompletedBasicSearch =
    basicSearchCriteria?.paidDate ||
    (basicSearchCriteria?.startDate && basicSearchCriteria?.endDate) ||
    basicSearchCriteria?.accountNumber;

  const earningsTable = (
    <div className="row">
      {!isDisbursementView && hasCompletedBasicSearch && (
        <div className="dropdown pull-right no-print">
          <DownloadButton
            paidDate={basicSearchCriteria?.paidDate}
            startDate={basicSearchCriteria?.startDate}
            endDate={basicSearchCriteria?.endDate}
            accountNumber={basicSearchCriteria?.accountNumber}
            getCsvLink={getEarningsAsCsvLink}
            useEarningsLink
          />
        </div>
      )}
      <EarningsTable
        initialPageEarningsLink={initialPageEarningsLink}
        columnDefinitions={columnDefinitions}
        compensationTypeFilterOptions={compensationTypeFilterOptions}
        commissionTypeFilterOptions={commissionTypeFilterOptions}
        compensationEventTypeFilterOptions={compensationEventTypeFilterOptions}
        premiumTypeFilterOptions={premiumTypeFilterOptions}
        defaultColumns={defaultColumns}
      />
    </div>
  );

  return (
    <div className="comp-hub-disbursements util-margin-50">
      <div className="row">
        <div className="col-md-12">
          {apiError && (
            <div className="no-print">
              <Alert severity="danger" message={apiError.message} errorList={apiError.errors} />
            </div>
          )}
          {!isLoading ? (
            <div>
              {/* begin disbursement-specific layer */}
              {(!apiError || disbursements) && isDisbursementView && disbursementPicker}
              {!isLoadingDisbursementDetail ? (
                <div>
                  <UserPreferencesProvider userPreferencesLink={preferencesLink}>
                    {!apiError && currentDisbursement && isDisbursementView && disbursementPayDetails}
                    {/* end disbursement-specific layer */}
                    {!apiError && (currentDisbursement || !isDisbursementView) && earningsTable}
                  </UserPreferencesProvider>
                </div>
              ) : (
                <LoadingPage />
              )}
            </div>
          ) : (
            <LoadingPage />
          )}
        </div>
      </div>
    </div>
  );
};

export default EarningsViewContainer;
