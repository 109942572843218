/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import moment from 'moment';
import AdvancedSearchCriteria from '../../models/AdvancedSearchCriteria';
import AdvancedSearchOption from '../../models/AdvancedSearchOption';
import '../Search/Search.css';
import './SearchPills.css';
import {
  useAdvancedSearchCriteria,
  useAdvancedSearchCriteriaUpdater,
  useAdvancedSearchOptions,
} from '../../hooks/useAdvancedSearchCriteria';

type FilterOptionValue = {
  key: string;
  label: string;
  value: string;
};

const formatDateHumanReadable = (date: string | undefined) => moment.utc(date).format('MM/DD/YYYY');

const deriveFiltersFromSearchCriteria = (
  searchCriteria: AdvancedSearchCriteria | undefined,
  filterOptions: AdvancedSearchOption[],
): FilterOptionValue[] => {
  const newFilters: FilterOptionValue[] = [];
  if (searchCriteria) {
    Object.keys(searchCriteria).forEach((key) => {
      if (key !== 'startDate' && key !== 'endDate') {
        const { label } = filterOptions.filter((option) => option.key === key)[0];
        const values = (searchCriteria as any)[key] as string[];
        values.forEach((value) =>
          newFilters.push({
            label,
            value,
            key,
          }),
        );
      }
    });

    if (searchCriteria.startDate && searchCriteria.endDate) {
      newFilters.push({
        key: 'paidDate',
        label: 'Paid Date',
        value: `${formatDateHumanReadable(searchCriteria.startDate)} - ${formatDateHumanReadable(
          searchCriteria.endDate,
        )}`,
      });
    }
  }

  return newFilters;
};

const SearchPills = () => {
  const [filters, setFilters] = useState<FilterOptionValue[]>([]);

  const searchCriteria: AdvancedSearchCriteria | undefined = useAdvancedSearchCriteria();
  const updateSearchCriteria = useAdvancedSearchCriteriaUpdater();
  const searchOptions = useAdvancedSearchOptions();

  useEffect(() => {
    const newFilters = deriveFiltersFromSearchCriteria(searchCriteria, searchOptions);
    setFilters(newFilters);
  }, [searchCriteria, searchOptions]);

  const removeFilter = (filterOption: FilterOptionValue) => {
    const newSearchCriteria: AdvancedSearchCriteria = { ...searchCriteria };
    if (filterOption.key === 'paidDate') {
      newSearchCriteria.startDate = undefined;
      newSearchCriteria.endDate = undefined;
    } else {
      const filterOptionValues = ((newSearchCriteria as any)[filterOption.key] as string[]).filter(
        (v) => v !== filterOption.value,
      );
      if (filterOptionValues.length === 0) {
        delete (newSearchCriteria as any)[filterOption.key];
      } else {
        (newSearchCriteria as any)[filterOption.key] = filterOptionValues;
      }
    }

    updateSearchCriteria(newSearchCriteria);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <ul className="list-inline hub-list-inline">
          {filters.map((filter: FilterOptionValue) => (
            <li key={`${filter.key} ${filter.value}`}>
              <span className="label label-primary bg-midnight util-padding-10">
                {`${filter.label}: ${filter.value}`}
                <button type="button" className="search close" aria-label="Close" onClick={() => removeFilter(filter)}>
                  <span aria-hidden="true" className="util-padding-left-10">
                    ×
                  </span>
                </button>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SearchPills;
