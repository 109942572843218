import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import NavLayout from '../navbar/Navbar/NavLayout';
import LocationAwareNavItem from '../navbar/Navbar/LocationAwareNavItem';

const Logout = () => {
  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
  });

  return (
    <>
      <NavLayout>
        <LocationAwareNavItem includesString="">
          <Link to="/">Log In</Link>
        </LocationAwareNavItem>
      </NavLayout>
      <div className="container">
        <div className="row text-center util-padding-bottom-10">
          <div className="col-md-12">
            <h1>You are logged out.</h1>
          </div>
        </div>
        <div className="row col-md-offset-3 text-center util-padding-vert-10">
          <div className="col-md-8">
            <p>
              Not what you wanted to do?
              <br />
              Use Log In above to access your information again.
            </p>
          </div>
        </div>
        <div className="row text-center util-padding-bottom-15">
          <div className="col-xs-12 text-center">
            <div className="lock-wrap">
              <svg className="horizon-svg lock" viewBox="0 0 106.3 170.8" preserveAspectRatio="xMidYMid meet">
                <g>
                  <path className="shackle animate-up-down svg-dark-cyan" />
                  <circle className="lock-body svg-light-cyan" />
                  <path className="keyhole svg-dark-cyan" />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Logout;
