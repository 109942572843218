import './Footer.css';
import Feedback from '../../feedback/Feedback/Feedback';

const currentYear = new Date().getFullYear();

const CUSTOMER_CONFIDENTIAL_TEXT =
  'Customer Confidential: This information is the property of Principal. ' +
  'It may not be shared without explicit permission of Principal.';
const COPYRIGHT_TEXT = `© ${currentYear}, Principal Financial Services, Inc.`;
const SECURITIES_TEXT = 'Securities offered through Principal Securities, Inc.,';
const MEMBER_SIPC = ' member SIPC';

interface Props {
  feedbackHref?: string;
  className?: string;
}

const Footer = (props: Props) => {
  const { feedbackHref, className } = props;

  return (
    <footer className={`comp-hub-footer ${className}`}>
      <section className="footer-row">
        <div className="footer-legal">
          <span className="legal-disclaimer">{CUSTOMER_CONFIDENTIAL_TEXT}</span>
        </div>
        <nav>
          <ul className="nav nav-footer nav-inline">
            <li>
              <a href="https://www.principal.com/terms-of-use">Terms of Use</a>
            </li>
            <li>
              <a href="https://www.principal.com/products-services-disclosures">Disclosures</a>
            </li>
            <li>
              <a href="https://www.principal.com/privacy-policies">Privacy</a>
            </li>
            <li>
              <a href="https://www.principal.com/security-policies">Security</a>
            </li>
            <li>
              <a href="https://www.principal.com/about-us/our-company/policies/report-fraud-or-unethical-conduct">
                Report Fraud
              </a>
            </li>
            <li>
              <a href="#placeholder">Site Map</a>
            </li>
          </ul>
        </nav>
        {feedbackHref && <Feedback feedbackHref={feedbackHref} />}
        <div className="footer-legal">
          <span className="legal-copyright">{COPYRIGHT_TEXT}</span>
          <span className="legal-statement">
            {SECURITIES_TEXT}
            <a href="http://www.sipc.org">{MEMBER_SIPC}</a>
          </span>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
