import { useEffect, useMemo, useReducer } from 'react';
import { tz } from 'moment-timezone';
import NotificationLevelEnum from '../Notification/NotificationLevelEnum';
import TextareaFormGroup from '../../shared/components/TextAreaFormGroup/TextareaFormGroup';
import SelectFormGroup from '../../shared/components/SelectFormGroup/SelectFormGroup';
import DateTimePickerFormGroup from '../../shared/components/DateTimePickerFormGroup/DateTimePickerFormGroup';
import InputFormGroup from '../../shared/components/InputFormGroup/InputFormGroup';
import Alert from '../../shared/components/Alert/Alert';
import { NotificationRequest } from '../models/NotificationRequest';
import Notification from '../models/Notification';
import Checkbox from '../../shared/components/Checkbox/Checkbox';
import ApiError, { FieldError } from '../../api/ApiError';
import RichContentEditableFormGroup from '../../shared/components/RichTextAreaFormGroup/RichContentEditableFormGroup';
import { Message } from '../../shared/components/RichTextAreaFormGroup/RichContentEditable';
import sanitize from '../../util/sanitizeHtml';

interface Props {
  onSubmit: (notification: NotificationRequest) => Promise<void>;
  notification?: Notification;
}

interface NotificationFormState {
  isLoading: boolean;
  error: ApiError | undefined;
  messageBody: Message;
  messageTitle: string;
  notificationLevel: NotificationLevelEnum;
  startDate: Date | undefined;
  endDate: Date | undefined;
  enterpriseIds: string[] | undefined;
  enabled: boolean;
}

const initialNotificationFormState: NotificationFormState = {
  isLoading: false,
  error: undefined,
  messageBody: { bodyAsHtml: '' },
  messageTitle: '',
  notificationLevel: 'INFO' as NotificationLevelEnum,
  startDate: undefined,
  endDate: undefined,
  enterpriseIds: [],
  enabled: true,
};

const MAX_TITLE_CHARACTERS = 50;

interface Action {
  type: string;
  payload?: Partial<NotificationFormState>;
}

function getErrorByField(field: string, errorBody: ApiError | undefined): string | undefined {
  return errorBody?.errors?.find((e) => e.field.includes(field))?.message;
}

const reducer = (state: NotificationFormState, action: Action) => {
  switch (action.type) {
    case 'init':
      return { ...state, ...action.payload };
    case 'change':
      return { ...state, ...action.payload };
    case 'submit':
      return { ...state, loading: true, error: undefined };
    case 'success':
      return { ...state, loading: false };
    case 'error':
      return { ...state, loading: false, error: action.payload?.error };
    default:
      return initialNotificationFormState;
  }
};

const NotificationForm = (props: Props) => {
  const { onSubmit, notification } = props;
  const [state, dispatch] = useReducer(reducer, initialNotificationFormState);
  const titleErrorMessage = useMemo(() => getErrorByField('title', state.error), [state.error]);
  const bodyErrorMessage = useMemo(() => getErrorByField('body', state.error), [state.error]);
  const enterpriseIdsErrorMessage = useMemo(() => getErrorByField('enterpriseIds', state.error), [state.error]);
  const startDateTimeErrorMessage = useMemo(() => getErrorByField('startDateTime', state.error), [state.error]);
  const endDateTimeErrorMessage = useMemo(() => getErrorByField('endDateTime', state.error), [state.error]);
  const currentTimezone = tz.guess();

  useEffect(() => {
    if (notification) {
      dispatch({
        type: 'init',
        payload: {
          startDate: new Date(notification.startDateTime),
          endDate: new Date(notification.endDateTime),
          messageTitle: notification.title,
          messageBody: { bodyAsHtml: notification.bodyAsHtml, bodyAsText: notification.bodyAsText },
          notificationLevel: notification.notificationLevel,
          enterpriseIds: notification.enterpriseIds,
          enabled: notification.enabled,
        },
      });
    }
  }, [notification, dispatch]);

  const onTitleChange = (messageTitle: string) => {
    dispatch({ type: 'change', payload: { messageTitle } });
  };

  const onBodyChange = (messageBody: Message) => {
    dispatch({ type: 'change', payload: { messageBody } });
  };

  const onEnterpriseIdsChange = (enterpriseIdString: string) => {
    const enterpriseIds = enterpriseIdString.split(',').map((eId) => eId.trim());
    dispatch({ type: 'change', payload: { enterpriseIds } });
  };

  const onLevelChange = (level: string) => {
    dispatch({ type: 'change', payload: { notificationLevel: level as NotificationLevelEnum } });
  };

  const onStartDateChange = (startDate: Date | undefined) => {
    dispatch({ type: 'change', payload: { startDate } });
  };

  const onEndDateChange = (endDate: Date | undefined) => {
    dispatch({ type: 'change', payload: { endDate } });
  };

  const onEnabledCheckboxChecked = (enabled: boolean) => {
    dispatch({ type: 'change', payload: { enabled } });
  };

  const onFormSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch({ type: 'submit' });
    if (state.startDate && state.endDate) {
      const notificationForSave: NotificationRequest = {
        title: state.messageTitle,
        bodyAsText: state.messageBody.bodyAsText,
        bodyAsHtml: state.messageBody.bodyAsHtml && sanitize(state.messageBody.bodyAsHtml),
        notificationLevel: state.notificationLevel,
        startDateTime: state.startDate.toISOString(),
        endDateTime: state.endDate.toISOString(),
        enabled: state.enabled,
        enterpriseIds: state.enterpriseIds?.filter((eId) => eId !== ''),
      };
      try {
        await onSubmit(notificationForSave);
      } catch (error) {
        dispatch({ type: 'error', payload: { error: error as ApiError } });
      }
    } else {
      const errors: FieldError[] = [];
      if (!state.startDate) {
        errors.push({ field: 'startDateTime', message: 'Date must be in this format: MM/dd/yyyy hh:mm AM/PM' });
      }
      if (!state.endDate) {
        errors.push({ field: 'endDateTime', message: 'Date must be in this format: MM/dd/yyyy hh:mm AM/PM' });
      }
      dispatch({ type: 'error', payload: { error: new ApiError('Error', 400, errors) } });
    }
  };

  return (
    <form onSubmit={onFormSubmit}>
      {state.error !== undefined && <Alert severity="danger" message={state.error.message} />}
      <div className="row">
        <div className="col-xs-12">
          <InputFormGroup
            inputId="title"
            labelText="Title"
            onChange={onTitleChange}
            maxCharacters={MAX_TITLE_CHARACTERS}
            required
            invalid={!!titleErrorMessage}
            feedback={titleErrorMessage}
            value={state.messageTitle}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <RichContentEditableFormGroup
            inputId="message"
            labelText="Message"
            invalid={Boolean(bodyErrorMessage)}
            feedback={bodyErrorMessage}
            onChange={onBodyChange}
            placeholder="Notification body"
            required
            value={state.messageBody}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <TextareaFormGroup
            inputId="enterpriseIds"
            labelText="Enterprise IDs (enter as comma separated list)"
            placeholder="enterprise id 1, enterprise id 2"
            onChange={onEnterpriseIdsChange}
            invalid={Boolean(enterpriseIdsErrorMessage)}
            feedback={enterpriseIdsErrorMessage}
            value={state.enterpriseIds?.join(', ')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <SelectFormGroup
            id="notificationLevelSelect"
            onChange={onLevelChange}
            options={Object.keys(NotificationLevelEnum).map((key) => ({
              key: NotificationLevelEnum[key as keyof typeof NotificationLevelEnum],
              value: key,
            }))}
            required
            label="Level"
            value={state.notificationLevel.valueOf().toUpperCase()}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <DateTimePickerFormGroup
            id="startDateTimePicker"
            label={`Start Date (${currentTimezone}):`}
            onChange={onStartDateChange}
            value={state.startDate}
            invalid={!!startDateTimeErrorMessage}
            feedback={startDateTimeErrorMessage}
            required
            autoComplete="off"
          />
        </div>
        <div className="col-xs-6">
          <DateTimePickerFormGroup
            id="endDateTimePicker"
            label={`End Date (${currentTimezone}):`}
            onChange={onEndDateChange}
            value={state.endDate}
            invalid={!!endDateTimeErrorMessage}
            feedback={endDateTimeErrorMessage}
            required
            autoComplete="off"
          />
        </div>
      </div>
      <div className="row">
        <Checkbox id="enabledCheckbox" label="Enabled" checked={state.enabled} onChange={onEnabledCheckboxChecked} />
      </div>
      <div className="row">
        <div className="col-xs-offset-8 col-xs-4">
          <button id="saveButton" type="submit" className="btn btn-primary pull-right" disabled={state.isLoading}>
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default NotificationForm;
