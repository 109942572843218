import { createContext, useContext, useState } from 'react';
import isEqual from 'lodash.isequal';
import AdvancedSearchCriteria from '../models/AdvancedSearchCriteria';
import AdvancedSearchOption from '../models/AdvancedSearchOption';
import { sanitizeObject } from '../../util/sanitizeObject';

interface Props {
  children?: React.ReactNode;
  searchOptions: AdvancedSearchOption[];
  sessionKey: string;
}

type UpdateFunction = (searchCriteria: AdvancedSearchCriteria) => void;

const SearchCriteriaContext = createContext<AdvancedSearchCriteria>({});
const SearchCriteriaUpdaterContext = createContext<UpdateFunction>(() => {});
const SearchOptionsContext = createContext<AdvancedSearchOption[]>([]);

const AdvancedSearchProvider = (props: Props) => {
  const { children, searchOptions, sessionKey } = props;
  const key = sessionKey;
  const [state, setState] = useState<AdvancedSearchCriteria>(() => JSON.parse(sessionStorage.getItem(key) || '{}'));

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const update = (newSearchCriteria: AdvancedSearchCriteria) => {
    setState((previousState) => {
      sanitizeObject(previousState);
      sanitizeObject(newSearchCriteria);
      if (!isEqual(previousState, newSearchCriteria)) {
        sessionStorage.setItem(sessionKey, JSON.stringify(newSearchCriteria));
        return newSearchCriteria;
      }
      return previousState;
    });
  };

  return (
    <SearchCriteriaContext.Provider value={state}>
      <SearchOptionsContext.Provider value={searchOptions}>
        <SearchCriteriaUpdaterContext.Provider value={update}>{children}</SearchCriteriaUpdaterContext.Provider>
      </SearchOptionsContext.Provider>
    </SearchCriteriaContext.Provider>
  );
};

const useAdvancedSearchCriteria = (): AdvancedSearchCriteria | undefined => useContext(SearchCriteriaContext);

const useAdvancedSearchCriteriaUpdater = (): UpdateFunction => {
  const updateSearchCriteria = useContext(SearchCriteriaUpdaterContext);
  if (updateSearchCriteria === undefined) {
    throw new Error('useSearchCriteriaUpdater must be used within Search Criteria Updater Context');
  }

  return updateSearchCriteria;
};

const useAdvancedSearchOptions = (): AdvancedSearchOption[] => {
  const searchOptions = useContext<AdvancedSearchOption[]>(SearchOptionsContext);
  if (searchOptions === undefined) {
    throw new Error('useSearchOptions must be used within Search Option Context');
  }

  return searchOptions;
};

export {
  AdvancedSearchProvider,
  useAdvancedSearchCriteria,
  useAdvancedSearchCriteriaUpdater,
  useAdvancedSearchOptions,
};
