import { useEffect } from 'react';

export type UseScriptParams = {
  url?: string;
  type: string;
  crossOrigin: string;
};

const useScript = ({ url, type, crossOrigin }: UseScriptParams) => {
  useEffect(() => {
    if (!url) {
      return undefined;
    }
    const script = document.createElement('script');

    script.src = url;
    script.crossOrigin = crossOrigin;
    script.type = type;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [url, crossOrigin, type]);
};

export default useScript;
