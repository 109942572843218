export default interface AdvancedSearchCriteria {
  accountNumbers?: string[];
  advisorNames?: string[];
  agreementHolderNames?: string[];
  commissionTypes?: string[];
  compensationTypes?: string[];
  compensationEventTypes?: string[];
  earnerNames?: string[];
  enterpriseIds?: string[];
  insuredNames?: string[];
  lastFourDigitsOfTaxIds?: string;
  names?: string[];
  nationalProducerNumbers?: string[];
  ownerNames?: string[];
  payeeNames?: string[];
  premiumTypes?: string[];
  productTypes?: string[];
  startDate?: string;
  endDate?: string;
  includeZeroDollarCompensationAmount?: boolean;
}

export const EMPTY_SEARCH_CRITERIA: AdvancedSearchCriteria = {};
