import { useEffect, useState } from 'react';
import get from 'lodash.get';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import InfiniteScroll from 'react-infinite-scroller';
import { mapToFormatter } from '../../../util/formatter';
import Earning from '../../../earnings/models/Earning';
import Column from '../../models/Column';
import './Table.css';
import DraggableTh from './DraggableTh/DraggableTh';
import LoadingSpinner from '../Loading/LoadingSpinner';
import Adjustment from '../../../adjustments/models/Adjustment';

interface Props {
  earnings?: Earning[];
  adjustments?: Adjustment[];
  columns: Column[];
  sortHandler: (c: Column) => void;
  loadMore: () => void;
  hasMore: boolean;
  onColumnReorder: (columnIds: string[]) => void;
  isLoadingContent: boolean;
}

export const ItemType = {
  TH: 'th',
};

const Table = (props: Props) => {
  const { columns, sortHandler, loadMore, earnings, adjustments, hasMore, onColumnReorder, isLoadingContent } = props;
  const [columnsToShow, setColumnsToShow] = useState<Column[]>([]);

  useEffect(() => {
    setColumnsToShow(columns.filter((c) => c.show));
  }, [columns]);

  const moveHeader = (dragIndex: number, hoverIndex: number) => {
    setColumnsToShow((previousColumnsToShow: Column[]) => {
      const dragHeader = previousColumnsToShow[dragIndex];
      const columnsWithoutDrag = previousColumnsToShow.slice(0, dragIndex);
      columnsWithoutDrag.push(...previousColumnsToShow.slice(dragIndex + 1));
      columnsWithoutDrag.splice(hoverIndex, 0, dragHeader);
      return columnsWithoutDrag;
    });
  };

  const saveColumnOnDrop = () => {
    onColumnReorder(columnsToShow.map((column) => column.id));
  };

  const onSortClick = (column: Column) => {
    switch (column.sortDirection) {
      case 'asc':
        column.isSorted = true;
        column.sortDirection = 'desc';
        break;
      case 'desc':
        column.isSorted = false;
        column.sortDirection = undefined;
        break;
      default:
        column.isSorted = true;
        column.sortDirection = 'asc';
    }
    sortHandler(column);
  };

  const contentRows = () => {
    if (earnings !== undefined) {
      return earningRows;
    }
    return adjustmentRows;
  };

  const earningRows =
    earnings?.length === 0 && !hasMore ? (
      <tr>
        <td colSpan={columnsToShow.length}>
          <p className="text-center">
            <em>No earnings found</em>
          </p>
        </td>
      </tr>
    ) : (
      earnings?.map((earning: Earning) => (
        <tr key={earning.id} className="earning-row" role="row">
          {columnsToShow.map((column: Column) => (
            <td key={column.id} className={column.className}>
              {mapToFormatter[column.format ? column.format : 'string'](get(earning, column.accessor))}
            </td>
          ))}
        </tr>
      ))
    );

  const adjustmentRows =
    adjustments?.length === 0 && !hasMore ? (
      <tr>
        <td colSpan={columnsToShow.length}>
          <p className="text-center">
            <em>No adjustments found</em>
          </p>
        </td>
      </tr>
    ) : (
      adjustments?.map((adjustment: Adjustment) => (
        <tr key={adjustment.id} className="adjustment-row" role="row">
          {columnsToShow.map((column: Column) => (
            <td key={column.id} className={column.className}>
              {mapToFormatter[column.format ? column.format : 'string'](get(adjustment, column.accessor))}
            </td>
          ))}
        </tr>
      ))
    );

  const loadingSpinner = (
    <tr key={0} className="no-print">
      <td colSpan={columnsToShow.length}>
        <LoadingSpinner />
      </td>
    </tr>
  );

  return (
    <div>
      <DndProvider backend={Backend}>
        <table className="table table-hover">
          <thead>
            <tr>
              {columnsToShow.map((column, index: number) => (
                <DraggableTh
                  key={column.id}
                  index={index}
                  column={column}
                  moveHeader={moveHeader}
                  sortHandler={onSortClick}
                  onDrop={saveColumnOnDrop}
                />
              ))}
            </tr>
          </thead>
          <InfiniteScroll
            element="tbody"
            loadMore={loadMore}
            hasMore={hasMore}
            initialLoad={false}
            loader={hasMore && !isLoadingContent ? loadingSpinner : undefined}
          >
            {isLoadingContent ? loadingSpinner : contentRows()}
          </InfiniteScroll>
        </table>
      </DndProvider>
    </div>
  );
};

export default Table;
