import SavedSearchListItems from '../SavedSearchListItems/SavedSearchListItems';
import SaveSearchModal from '../SaveSearchModal/SaveSearchModal';
import './SavedSearchMenu.css';

const SavedSearchMenu = () => (
  <ul className="dropdown-menu dropdown-menu-right hub-dropdown-menu">
    <SavedSearchListItems />
    <li role="separator" className="divider" />
    <SaveSearchModal />
  </ul>
);

export default SavedSearchMenu;
