import { useState } from 'react';
import assert from 'assert';
import EarningApi from '../../api/earnings-api';
import Formatter from '../../util/formatter';
import Link from '../../shared/models/Link';
import { useBasicSearchCriteria } from '../../search/hooks/useBasicSearchCriteria';
import { useUserPreferences } from '../../user-preferences/UserPreferencesProvider';
import { useAdvancedSearchCriteria } from '../../search/hooks/useAdvancedSearchCriteria';
import DisbursementsApi from '../../api/disbursements-api';
import { CompensationType } from '../PayDetails/PayDetails';

interface Props {
  paidDate?: string;
  startDate?: string;
  endDate?: string;
  accountNumber?: string;
  getCsvLink: Link;
  useEarningsLink?: boolean;
  compensationType?: CompensationType;
}

const DownloadButton = (props: Props) => {
  const { paidDate, startDate, endDate, accountNumber, getCsvLink, useEarningsLink, compensationType } = props;

  const [isLoading, setLoading] = useState<boolean>(false);
  const basicSearchCriteria = useBasicSearchCriteria();
  const advancedSearchCriteria = useAdvancedSearchCriteria();
  const { userPreferences } = useUserPreferences();

  const getDataByUrlAsCsv = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const { currentTarget } = event;
    setLoading(true);
    event.preventDefault();
    const filename = getFilename();

    if (useEarningsLink && basicSearchCriteria) {
      const csvDownloadBasicSearchCriteria = {
        ...basicSearchCriteria,
        includeZeroDollarCompensationAmount: userPreferences?.includeZeroDollarCompensationAmount,
      };
      await EarningApi.findEarningsByUrlAsCsv(getCsvLink, filename, csvDownloadBasicSearchCriteria);
    } else {
      const csvDownloadAdvancedSearchCriteria = {
        ...advancedSearchCriteria,
        includeZeroDollarCompensationAmount: userPreferences?.includeZeroDollarCompensationAmount,
      };
      if (compensationType === CompensationType.DISBURSEMENT) {
        await DisbursementsApi.getDisbursementEarningsAsCsv(getCsvLink, csvDownloadAdvancedSearchCriteria, filename);
      } else {
        await DisbursementsApi.getAdjustmentAsCsv(getCsvLink, filename);
      }
    }

    currentTarget.blur();
    setLoading(false);
  };

  const getFilename = () => {
    if (startDate !== undefined && endDate !== undefined) {
      return `${Formatter.isoToFilename(startDate)}_${Formatter.isoToFilename(endDate)}.csv`;
    }
    if (accountNumber !== undefined) {
      return `${accountNumber}.csv`;
    }
    assert(paidDate);
    return `${Formatter.isoToFilename(paidDate)}.csv`;
  };

  const handleCSVDownloadLinkClick = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    await getDataByUrlAsCsv(event);
  };

  const toolTipText =
    compensationType !== CompensationType.ADJUSTMENT
      ? "Download a CSV file with the 'Rows to show' criteria applied."
      : undefined;

  return (
    <>
      {toolTipText && (
        <a href="# " data-toggle="tooltip" title={toolTipText}>
          <i className="fa fa-info-circle help-tip" />
        </a>
      )}
      &nbsp;&nbsp;
      <a href="# " onClick={handleCSVDownloadLinkClick} data-gtm="download" data-type="csv">
        <button className="btn btn-default" type="button" disabled={isLoading}>
          Download CSV
        </button>
      </a>
    </>
  );
};
export default DownloadButton;
