import { useLocation } from 'react-router-dom';
import React from 'react';

interface LocationAwareNavItemProps {
  includesString: string;
}

const LocationAwareNavItem = ({ includesString, children }: React.PropsWithChildren<LocationAwareNavItemProps>) => {
  const location = useLocation();
  return <li className={location.pathname.includes(includesString) ? 'active' : ''}>{children}</li>;
};
export default LocationAwareNavItem;
