import './RichContentEditable.css';
import { EditorState } from 'draft-js';
import InlineStyleButtons from './InlineStyleButtons';
import BlockStyleButtons from './BlockStyleButtons';
import LinkStyleButtons from './LinkStyleButtons';

export interface RichTextAreaToolbarProps {
  editorState: EditorState;
  onContentChange: (editorState: EditorState) => void;
  disabled?: boolean;
}

export interface StyleButtonProps {
  styleName: string;
  editorState: EditorState;
  onContentChange: (editorState: EditorState) => void;
  disabled?: boolean;
}

const RichContentEditableToolbar = (props: RichTextAreaToolbarProps) => {
  const { editorState, onContentChange, disabled } = props;

  return (
    <div className="btn-toolbar panel-heading" role="toolbar">
      <InlineStyleButtons editorState={editorState} onContentChange={onContentChange} disabled={disabled} />
      <BlockStyleButtons editorState={editorState} onContentChange={onContentChange} disabled={disabled} />
      <LinkStyleButtons editorState={editorState} onContentChange={onContentChange} disabled={disabled} />
    </div>
  );
};

export default RichContentEditableToolbar;
