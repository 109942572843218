import HubFetch from './hub-fetch';
import SavedSearch from '../search/models/SavedSearch';
import SavedSearchRequest from '../search/models/SaveSearchRequest';
import ApiError from './ApiError';

const headers = new Headers();
headers.set('Content-Type', 'application/json');

export const save = async (savedSearch: SavedSearchRequest) => {
  const baseUrl = process.env.REACT_APP_COMPENSATION_HUB_API;
  const method = 'POST';
  const body = JSON.stringify(savedSearch);
  try {
    await HubFetch.hubFetch(`${baseUrl}/user/saved-searches`, { method, headers, body });
  } catch (e) {
    throw new ApiError('Unable to create saved filter.', (e as ApiError).status, (e as ApiError).errors);
  }
};

export const findAll = async (): Promise<SavedSearch[]> => {
  const baseUrl = process.env.REACT_APP_COMPENSATION_HUB_API;
  const collectionModel = JSON.parse(await HubFetch.hubFetch(`${baseUrl}/user/saved-searches`));
  if (!collectionModel?._embedded?.savedSearches) {
    return [];
  }
  return collectionModel._embedded.savedSearches;
};

export const deleteById = async (savedSearchId: string) => {
  const baseUrl = process.env.REACT_APP_COMPENSATION_HUB_API;
  const method = 'DELETE';

  try {
    await HubFetch.hubFetch(`${baseUrl}/user/saved-searches/${savedSearchId}`, { method });
  } catch (e) {
    // fail silently
  }
};
