import { useHistory } from 'react-router';
import Link from '../../shared/models/Link';
import NotificationForm from './NotificationForm';
import { NotificationRequest } from '../models/NotificationRequest';
import { createNotification } from '../../api/notifications-api';

interface Props {
  notificationsLink: Link;
}

const NewNotification = (props: Props) => {
  const history = useHistory();
  const { notificationsLink } = props;

  const onSubmit = async (request: NotificationRequest) => {
    await createNotification(notificationsLink, request);
    history.push('/operations/notifications');
  };

  return (
    <>
      <h1>New Notification</h1>
      <NotificationForm onSubmit={onSubmit} />
    </>
  );
};

export default NewNotification;
