import { useEffect, useState } from 'react';
import DisbursementSummary from '../models/DisbursementSummary';
import './DisbursementPicker.css';
import Formatter from '../../util/formatter';
import Link from '../../shared/models/Link';
import Alert, { AlertProps } from '../../shared/components/Alert/Alert';
import { MY_PAY } from '../../search/models/BasicSearchOption';

interface Props {
  disbursements: DisbursementSummary[];
  handleDisbursementClickCallback: (disbursementLink: Link, disbursementContentLink: Link | undefined) => void;
  alert?: AlertProps;
  hidePayMethod?: boolean;
  isOps?: boolean;
  basicSearchOption?: string;
  currentSearchType?: string;
}

const MAX_SIZE = 10;

const DisbursementPicker: React.FC<Props> = (props: Props) => {
  const { disbursements, alert, hidePayMethod, basicSearchOption, currentSearchType, isOps } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [minDisplayIndex, setMinDisplayIndex] = useState(0);

  const rightArrowEnabled = (): boolean => minDisplayIndex < disbursements.length - MAX_SIZE;

  const onRightArrowClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.preventDefault();
    if (rightArrowEnabled()) {
      setMinDisplayIndex(Math.min(disbursements.length - MAX_SIZE, minDisplayIndex + MAX_SIZE - 1));
    }
  };

  const leftArrowEnabled = (): boolean => minDisplayIndex > 0;

  const onLeftArrowClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.preventDefault();
    if (leftArrowEnabled()) {
      setMinDisplayIndex(Math.max(0, minDisplayIndex - MAX_SIZE + 1));
    }
  };

  const onDisbursementClick = (disbursement: DisbursementSummary): void => {
    const { handleDisbursementClickCallback } = props;
    setCurrentIndex(disbursement.index);
    const disbursementContentLink = disbursement._links.earnings ?? disbursement._links.adjustments;
    handleDisbursementClickCallback(disbursement._links.disbursement, disbursementContentLink);
  };

  useEffect(() => {
    setCurrentIndex(disbursements.length - 1);
    setMinDisplayIndex(Math.max(0, disbursements.length - MAX_SIZE));
  }, [disbursements]);

  if (!disbursements) {
    return <h3>Loading…</h3>;
  }

  return (
    <div id="DisbursementPicker" className="text-center no-print">
      {disbursements.length === 0 && alert ? (
        <Alert message={alert.message} severity={alert.severity} />
      ) : (
        <ul className="pagination">
          <li data-testid="left-arrow" className={leftArrowEnabled() ? '' : 'disabled'}>
            <a href="# " className="disbursement-nav" onClick={onLeftArrowClick}>
              <em className="fa fa-angle-left disbursement-nav-arrow-em" />
            </a>
          </li>
          {disbursements.slice(minDisplayIndex, minDisplayIndex + MAX_SIZE).map((disbursement) => (
            <li key={disbursement.index} className={currentIndex === disbursement.index ? 'active' : ''}>
              <a
                href="# "
                className="disbursement-nav disbursement-nav-element"
                onClick={(event): void => {
                  event.preventDefault();
                  onDisbursementClick(disbursement);
                }}
              >
                <div>{disbursement.payeeName}</div>
                <hr className="line" />
                <div>{Formatter.isoToLongDate(disbursement.paidDate)}</div>
                {(isOps || (basicSearchOption === MY_PAY && currentSearchType === 'basic')) && (
                  <>
                    <hr className="line" />
                    <div>${Formatter.numberToUSD(disbursement.compensationAmount)}</div>
                  </>
                )}
                <hr className="line" />
                {!hidePayMethod && (
                  <div className="pay-method">
                    {disbursement.payMethod === 'Direct Deposit' ? 'Direct Dep' : disbursement.payMethod}
                  </div>
                )}
              </a>
            </li>
          ))}
          <li data-testid="right-arrow" className={rightArrowEnabled() ? '' : 'disabled'}>
            <a href="# " className="disbursement-nav" onClick={onRightArrowClick}>
              <em className="fa fa-angle-right disbursement-nav-arrow-em" />
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default DisbursementPicker;
