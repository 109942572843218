import { createContext, useContext, useEffect, useReducer } from 'react';
import difference from 'lodash.difference';
import isEqual from 'lodash.isequal';
import UserPreferences from './UserPreferences';
import Column from '../shared/models/Column';
import { get, save } from '../api/preferences-api';
import Link from '../shared/models/Link';
import usePrevious from '../shared/hooks/usePrevious';

interface Props {
  children?: React.ReactNode;
  userPreferencesLink: Link;
}

type ColumnSelectionCollapsedAction = { type: 'column-selection-collapsed' };
type IncludeZeroDollarCompensationAmountAction = { type: 'include-zero-dollar-compensation-amount' };
type SortColumnSelectedAction = { type: 'sort-column'; payload: { sortedColumns: Column[] } };
type SelectColumnAction = { type: 'select-column'; payload: { selectedColumns: Column[] } };
type ColumnsReorderedAction = { type: 'columns-reordered'; payload: { selectedColumns: Column[] } };
type ResetSelectedColumnsAction = { type: 'reset-columns-and-rows'; payload: { defaultColumns: string[] } };
type InitializeAction = { type: 'initialize'; payload: UserPreferences };
type Action =
  | ColumnSelectionCollapsedAction
  | IncludeZeroDollarCompensationAmountAction
  | SortColumnSelectedAction
  | SelectColumnAction
  | ColumnsReorderedAction
  | ResetSelectedColumnsAction
  | InitializeAction;

type Dispatch = (action: Action) => void;

const UserPreferencesStateContext = createContext<UserPreferences | undefined>(undefined);
const UserPreferencesDispatchContext = createContext<Dispatch | undefined>(undefined);

const reducer = (state: UserPreferences | undefined, action: Action): UserPreferences | undefined => {
  // the following actions are only available after the state is defined
  if (state) {
    switch (action.type) {
      case 'column-selection-collapsed': {
        return { ...state, columnSelectionCollapsed: !state?.columnSelectionCollapsed };
      }
      case 'sort-column': {
        if (action.payload.sortedColumns) {
          return {
            ...state,
            sortedColumns: action.payload.sortedColumns
              .filter((c) => c.sortDirection !== undefined)
              .map((c) => ({ id: c.id, sortDirection: c.sortDirection as 'asc' | 'desc' })),
          };
        }
        return state;
      }
      case 'columns-reordered': {
        return { ...state, selectedColumns: action.payload.selectedColumns.map((c) => c.id) };
      }
      case 'select-column': {
        const defaultColumns = action.payload.selectedColumns.map((c) => c.id);
        const sortedColumns = state.sortedColumns?.filter((c) => defaultColumns.includes(c.id));

        return { ...state, selectedColumns: defaultColumns, sortedColumns };
      }
      case 'reset-columns-and-rows': {
        return {
          ...state,
          selectedColumns: action.payload.defaultColumns,
          sortedColumns: [],
          includeZeroDollarCompensationAmount: true,
        };
      }
      case 'include-zero-dollar-compensation-amount': {
        return {
          ...state,
          includeZeroDollarCompensationAmount: !state?.includeZeroDollarCompensationAmount,
        };
      }
      default: {
        return { ...action.payload };
      }
    }
  } else {
    switch (action.type) {
      case 'initialize': {
        return action.payload;
      }
      default: {
        return undefined;
      }
    }
  }
};

const arrayHasSameValues = (array1: string[], array2: string[]) => isEqual([...array1].sort(), [...array2].sort());

const findArrayDifference = (
  oldArray: string[],
  newArray: string[],
): { elementsAdded: string[]; elementsRemoved: string[] } => {
  const elementsRemoved = difference(oldArray, newArray);
  const elementsAdded = difference(newArray, oldArray);
  return { elementsAdded, elementsRemoved };
};

const UserPreferencesProvider = (props: Props) => {
  const { children, userPreferencesLink } = props;
  const [preferences, dispatch] = useReducer(reducer, undefined);
  const previousPreferences = usePrevious(preferences);

  useEffect(() => {
    const init = async () => {
      const userPreferences = await get(userPreferencesLink);
      dispatch({ type: 'initialize', payload: userPreferences });
    };

    init();
  }, [userPreferencesLink]);

  useEffect(() => {
    if (preferences !== undefined && previousPreferences !== undefined && !isEqual(preferences, previousPreferences)) {
      save(preferences);
    }

    if (
      preferences !== undefined &&
      previousPreferences !== undefined &&
      !arrayHasSameValues(preferences.selectedColumns, previousPreferences.selectedColumns)
    ) {
      findArrayDifference(previousPreferences.selectedColumns, preferences.selectedColumns);
    }
  }, [preferences, previousPreferences]);

  return (
    <UserPreferencesStateContext.Provider value={preferences}>
      <UserPreferencesDispatchContext.Provider value={dispatch}>{children}</UserPreferencesDispatchContext.Provider>
    </UserPreferencesStateContext.Provider>
  );
};

const useUserPreferences = () => ({
  userPreferences: useContext(UserPreferencesStateContext),
  dispatch: useContext(UserPreferencesDispatchContext),
});

export { UserPreferencesProvider, useUserPreferences };
