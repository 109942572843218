import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import InputFormGroup from '../../../shared/components/InputFormGroup/InputFormGroup';
import { useAdvancedSearchCriteria } from '../../hooks/useAdvancedSearchCriteria';
import useCreateSaveSearch from '../../hooks/useCreateSaveSearch';
import Alert from '../../../shared/components/Alert/Alert';
import ApiError from '../../../api/ApiError';

const SaveSearchModal = () => {
  const [title, setTitle] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiError>();
  const searchCriteria = useAdvancedSearchCriteria();
  const [mutate] = useCreateSaveSearch();

  const onSaveButtonClick = () => {
    setShow(true);
  };

  const onHide = () => {
    setShow(false);
  };

  const onSave = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setApiError(undefined);
    if (searchCriteria) {
      try {
        await mutate({ title, searchCriteria });
        onHide();
      } catch (e) {
        setApiError(e as ApiError);
      }
    }
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
      <li onClick={onSaveButtonClick}>
        <a href="# ">Save Current Search</a>
      </li>
      <Modal show={show} onHide={onHide}>
        <ModalHeader>
          <ModalTitle>Save Search</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {apiError && <Alert severity="danger" message="Error saving filter" />}
          <form onSubmit={onSave}>
            <InputFormGroup
              inputId="saveSearchTitleInput"
              labelText="Title"
              onChange={setTitle}
              value={title}
              required
              invalid={apiError?.errors?.some((e) => e.field === 'title')}
              feedback={apiError?.errors?.find((e) => e.field === 'title')?.message}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <button type="submit" id="saveSearchButton" className="btn btn-primary pull-right" onClick={onSave}>
            Save
          </button>
          <button type="button" id="cancelSaveSearchButton" className="btn btn-default pull-left" onClick={onHide}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SaveSearchModal;
