import { useUserPreferences } from '../../../user-preferences/UserPreferencesProvider';
import Checkbox from '../../../shared/components/Checkbox/Checkbox';

const RowSelector = () => {
  const { userPreferences, dispatch } = useUserPreferences();

  const onToggleZeroDollarCompensationAmount = () => {
    if (dispatch) {
      dispatch({ type: 'include-zero-dollar-compensation-amount' });
    }
  };

  return (
    <>
      <h4>Rows to show</h4>
      <Checkbox
        checked={userPreferences?.includeZeroDollarCompensationAmount}
        id="checkbox-includeZeroDollarCompensationAmount"
        label="$0.00 Amount Paid"
        onChange={onToggleZeroDollarCompensationAmount}
        title="Show earnings where the amount paid was $0.00"
      />
    </>
  );
};

export default RowSelector;
