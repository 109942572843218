import { useReducer } from 'react';
import './Feedback.css';
import * as FeedbackApi from '../../api/feedback-api';
import TextareaFormGroup from '../../shared/components/TextAreaFormGroup/TextareaFormGroup';
import ApiError from '../../api/ApiError';
import Alert from '../../shared/components/Alert/Alert';

interface Props {
  feedbackHref: string;
}

interface FormState {
  apiError?: ApiError;
  loading: boolean;
  disabled: boolean;
  characterCount: number;
  feedback: string;
  isSuccessful: boolean;
}

interface Action {
  type: string;
  payload?: Partial<FormState>;
}

export const FeedbackModalId = 'feedback-modal';

const MAX_CHARACTER_COUNT = 4000;

const INITIAL_STATE: FormState = {
  apiError: undefined,
  loading: false,
  disabled: false,
  characterCount: MAX_CHARACTER_COUNT,
  feedback: '',
  isSuccessful: false,
};
const reducer = (state: FormState, action: Action) => {
  switch (action.type) {
    case 'success':
      return { ...state, loading: false, isSuccessful: true };
    case 'error':
      return {
        ...state,
        loading: false,
        ...action.payload,
        isSuccessful: false,
      };
    case 'close':
      return INITIAL_STATE;
    case 'submit':
      return { ...state, loading: true, disabled: true };
    case 'change':
      return {
        ...state,
        ...action.payload,
        characterCount: MAX_CHARACTER_COUNT - (action.payload?.feedback?.length || 0),
      };
    default:
      return INITIAL_STATE;
  }
};

const Feedback = (props: Props) => {
  const { feedbackHref } = props;
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const onTextChange = (feedback: string) => {
    dispatch({ type: 'change', payload: { feedback } });
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    dispatch({ type: 'submit' });
    event.preventDefault();
    try {
      await FeedbackApi.sendFeedback(feedbackHref, state.feedback);
      dispatch({ type: 'success' });
    } catch (e) {
      dispatch({ type: 'error', payload: { apiError: e as ApiError } });
    }
  };

  const handleClose = () => {
    dispatch({ type: 'close' });
  };

  return (
    <div className="modal fade" id={FeedbackModalId} role="dialog" aria-labelledby="example-modal-label">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title" id="example-modal-label">
              Submit Feedback to the Sales Compensation Reporting Team
            </h4>
            {state.loading && <em className="modal-spinner fa fa-spinner fa-spin" />}
            {state.apiError !== undefined && <Alert severity="danger" message={state.apiError.message} />}
            {state.isSuccessful && <Alert severity="success" message="Thanks for the feedback!" />}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="form-group">
                <TextareaFormGroup
                  inputId="message-text"
                  disabled={state.disabled}
                  value={state.feedback}
                  onChange={onTextChange}
                  maxCharacters={MAX_CHARACTER_COUNT}
                  feedback={state.apiError?.errors?.find((f) => f.field === 'body')?.message}
                  invalid={state.apiError?.errors?.some((f) => f.field === 'body')}
                  required
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-link pull-left" data-dismiss="modal" onClick={handleClose}>
                Close
              </button>
              <button type="submit" className="btn btn-primary pull-right" disabled={state.disabled}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
