import HubFetch from './hub-fetch';
import Notification from '../notifications/models/Notification';
import Link from '../shared/models/Link';
import NotificationSearchRequest from '../notifications/models/NotificationSearchRequest';
import { NotificationRequest } from '../notifications/models/NotificationRequest';
import ApiError from './ApiError';

export async function getNotification(notificationLink: Link): Promise<Notification> {
  const response = await HubFetch.hubFetch(notificationLink.href);
  return JSON.parse(response);
}

// these are the notifications that a user would see when they first log in
export async function getActiveNotificationsForUser(url: string): Promise<Notification[]> {
  const response = await HubFetch.hubFetch(url);
  const notificationsPayload = JSON.parse(response);
  if (notificationsPayload._embedded && notificationsPayload._embedded.notificationList) {
    return notificationsPayload._embedded.notificationList;
  }
  return [];
}

export async function createNotification(notificationLink: Link, notification: NotificationRequest): Promise<string> {
  const method = 'POST';
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  const body = JSON.stringify(notification);
  const init: RequestInit = { method, headers, body };

  try {
    return await HubFetch.hubFetch(notificationLink.href, init);
  } catch (e) {
    const { status, errors } = e as ApiError;
    throw new ApiError('Unable to create notification.', status, errors);
  }
}

export async function updateNotification(notificationLink: Link, updateNotificationRequest: NotificationRequest) {
  const method = 'PATCH';
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  const body = JSON.stringify(updateNotificationRequest);
  const init: RequestInit = { method, headers, body };
  try {
    return await HubFetch.hubFetch(notificationLink.href, init);
  } catch (e) {
    const { status, errors } = e as ApiError;
    throw new ApiError('Unable to update notification.', status, errors);
  }
}

// these are the notifications that populate the table for ops users under the 'Notifications' tab in the navbar
export async function getAllNotificationsForOpsUser(notificationLink: Link): Promise<Notification[]> {
  const method = 'POST';
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  // this is ignored in hub-api - should we get rid of it?
  const searchRequest: NotificationSearchRequest = {
    startDateTime: new Date(Date.now()).toISOString(),
  };
  const body = JSON.stringify(searchRequest);
  const init: RequestInit = { method, headers, body };

  const response = await HubFetch.hubFetch(notificationLink.href, init);
  const notificationsPayload = JSON.parse(response);
  if (notificationsPayload._embedded && notificationsPayload._embedded.notificationList) {
    return notificationsPayload._embedded.notificationList;
  }
  return [];
}
