import { queryCache, useMutation } from 'react-query';
import SaveSearchRequest from '../models/SaveSearchRequest';
import { save } from '../../api/saved-search-api';

async function saveSearch(saveSearchRequest: SaveSearchRequest) {
  await save(saveSearchRequest);
}

export default function useCreateSaveSearch() {
  return useMutation(saveSearch, {
    throwOnError: true,
    onSuccess: () => {
      queryCache.invalidateQueries(['saved-searches']);
    },
  });
}
