import BasicSearchOption from '../../models/BasicSearchOption';

interface BasicSearchOptionsProps {
  basicSearchOptions: BasicSearchOption[];
  currentOption: BasicSearchOption;
  setCurrentOption: (option: BasicSearchOption) => void;
}

const BasicSearchOptions = (props: BasicSearchOptionsProps) => {
  const { basicSearchOptions, currentOption, setCurrentOption } = props;

  const createOnClick = (option: BasicSearchOption) => (event: React.MouseEvent) => {
    event.preventDefault();
    setCurrentOption(option);
  };

  return (
    <div>
      {basicSearchOptions && (
        <div>
          <div>
            <label>I want to see:</label>
          </div>
          <div className="btn-group" role="group">
            {basicSearchOptions.map((option) => (
              <button
                key={option.key}
                type="button"
                title={option.tooltipText}
                className={option.key === currentOption.key ? 'btn btn-default selected' : 'btn btn-default'}
                onClick={createOnClick(option)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicSearchOptions;
