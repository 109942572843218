import { useEffect, useState } from 'react';
import Column from '../../../shared/models/Column';
import categories from '../column-categories';
import { useUserPreferences } from '../../../user-preferences/UserPreferencesProvider';
import Checkbox from '../../../shared/components/Checkbox/Checkbox';

interface Props {
  columnDefinitions: Column[];
  defaultColumns: string[];
}

const categorizeColumns = (selectedColumnIds: string[], columnDefinitions: Column[]) => {
  const selectedColumns: Column[] = [];

  selectedColumnIds.forEach((selectedColumnId) => {
    const maybeColumnDefinition = columnDefinitions.find((column) => column.id === selectedColumnId);
    if (maybeColumnDefinition !== undefined) {
      selectedColumns.push({
        ...maybeColumnDefinition,
        show: true,
        category: 'Default',
      } as Column);
    }
  });

  const unselectedColumns: Column[] = columnDefinitions.filter((column) => !selectedColumnIds.includes(column.id));

  return [...selectedColumns, ...unselectedColumns];
};

const ColumnSelector = (props: Props) => {
  const { columnDefinitions, defaultColumns } = props;
  const [columns, setColumns] = useState<Column[]>([]);
  const { userPreferences, dispatch } = useUserPreferences();

  useEffect(() => {
    if (userPreferences) {
      setColumns(categorizeColumns(userPreferences?.selectedColumns, columnDefinitions));
    }
  }, [columnDefinitions, userPreferences]);

  const onColumnSelectorReset = () => {
    if (dispatch) {
      dispatch({ type: 'reset-columns-and-rows', payload: { defaultColumns } });
    }
  };

  const onColumnSelected = (toggledColumn: Column) => {
    const newColumns = columns.map((c: Column) => (toggledColumn.id === c.id ? { ...c, show: !c.show } : c));
    const displayedColumns = newColumns.filter((c) => c.show);
    if (dispatch) {
      dispatch({ type: 'select-column', payload: { selectedColumns: displayedColumns } });
    }
  };

  const renderDefaultCategory = () => (
    <h5 className="row">
      <div className="col-md-7 hub-util-h5-btn-align-vertical">Default</div>
      <div className="col-md-5">
        <button className="btn-link" id="table-filter-reset-button" onClick={onColumnSelectorReset} type="button">
          Reset
        </button>
      </div>
    </h5>
  );

  return (
    <>
      <h4>Columns to show</h4>
      {categories.map((category) => (
        <div className={`category-${category}`} key={category}>
          {category === 'Default' ? renderDefaultCategory() : <h5>{category}</h5>}
          {columns
            .filter((column) => column.category === category)
            .map((column) => (
              <Checkbox
                checked={column.show}
                id={`checkbox-${column.id}`}
                key={column.id}
                label={column.header}
                title={column.tooltipText}
                onChange={() => onColumnSelected(column)}
              />
            ))}
        </div>
      ))}
    </>
  );
};

export default ColumnSelector;
