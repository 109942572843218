import { useMemo, useState } from 'react';
import './Notification.css';
import NotificationLevelEnum from './NotificationLevelEnum';
import sanitize from '../../util/sanitizeHtml';

interface Props {
  title?: string;
  body: string;
  notificationLevel: NotificationLevelEnum;
  id: string;
}

const Notification = (props: Props) => {
  const { title, body, notificationLevel, id } = props;
  const [show, setShow] = useState(true);

  const onClose = () => {
    setShow(false);
  };

  const cleanHtml = useMemo(() => sanitize(body), [body]);

  if (show) {
    return (
      <div
        role="alert"
        className={`alert alert-${notificationLevel.toLowerCase()} alert-dismissible alert-icon alert-icon-lg`}
      >
        <button type="button" className="close" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true">×</span>
        </button>
        {title && <h4>{title}</h4>}
        {/* eslint-disable-next-line react/no-danger */}
        <div id={`notification-body-${id}`} dangerouslySetInnerHTML={{ __html: cleanHtml }} />
      </div>
    );
  }
  return <div />;
};

export default Notification;
