import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js';
import { ReactElement } from 'react';

export interface RichTextLinkProps {
  contentState: ContentState;
  entityKey: string;
  children?: Array<ReactElement>;
}

function findLinkEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

const Link = (props: RichTextLinkProps) => {
  const { contentState, entityKey, children } = props;
  const { url } = contentState.getEntity(entityKey).getData();
  return <a href={url}>{children}</a>;
};

const RichContentEditableLinkDecorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

export default RichContentEditableLinkDecorator;
