import DisbursementSummary from '../disbursements/models/DisbursementSummary';
import Disbursement from '../disbursements/models/Disbursement';
import DisbursementView from '../disbursements/models/DisbursementView';
import Link from '../shared/models/Link';
import HubFetch from './hub-fetch';
import AdvancedSearchCriteria, { EMPTY_SEARCH_CRITERIA } from '../search/models/AdvancedSearchCriteria';
import ApiError from './ApiError';
import CancelablePromise from '../util/CancelablePromise';
import AdjustmentsPage from '../adjustments/models/AdjustmentsPage';
import PageMetadata from '../shared/models/PageMetadata';
import Navigation from '../shared/models/Navigation';

const headers = new Headers({ 'Content-Type': 'application/json' });

const dateStringAscending = (a: DisbursementSummary, b: DisbursementSummary): number =>
  a.paidDate.localeCompare(b.paidDate);
const mapToDisbursement = (d: DisbursementSummary, index: number): DisbursementSummary => ({ ...d, index });

async function getDisbursementByUrl(disbursementLink: Link): Promise<Disbursement> {
  const response = await HubFetch.hubFetch(disbursementLink.href);
  return JSON.parse(response);
}

type SearchCriteria = AdvancedSearchCriteria | undefined;

async function getDisbursementsViewAndSummaries(
  searchCriteria: SearchCriteria,
  disbursementsUrl: string,
): Promise<DisbursementView> {
  const body = JSON.stringify(searchCriteria);
  const method = 'POST';
  const init: RequestInit = { method, body, headers };
  let response;
  try {
    response = await HubFetch.hubFetch(disbursementsUrl, init);
  } catch (e) {
    const { status, errors } = e as ApiError;
    throw new ApiError('Unable to get disbursements. Applied filter contains the following errors:', status, errors);
  }
  const json = JSON.parse(response);
  const disbursements: DisbursementSummary[] = json.disbursementSummaries
    .sort(dateStringAscending)
    .map(mapToDisbursement);

  return {
    disbursementSummaries: disbursements,
    distributionPartner: json.distributionPartner,
  };
}

async function searchByUrl(endpoint: string, criteria: AdvancedSearchCriteria): Promise<AdjustmentsPage> {
  const method = 'POST';
  const body = JSON.stringify(criteria);

  try {
    const text = await HubFetch.hubFetch(endpoint, { method, headers, body });
    const adjustmentsPayload = JSON.parse(text);

    let adjustmentsList = [];
    if (adjustmentsPayload._embedded && adjustmentsPayload._embedded.adjustmentList) {
      adjustmentsList = adjustmentsPayload._embedded.adjustmentList;
    }

    const page = new PageMetadata(
      adjustmentsPayload.page.number,
      adjustmentsPayload.page.size,
      adjustmentsPayload.page.totalElements,
      adjustmentsPayload.page.totalPages,
    );

    return new AdjustmentsPage(adjustmentsList, adjustmentsPayload._links, page);
  } catch (e) {
    return new AdjustmentsPage([], {} as Navigation, new PageMetadata(0, 0, 0, 0));
  }
}

function search(
  link: Link,
  criteria: AdvancedSearchCriteria = EMPTY_SEARCH_CRITERIA,
): CancelablePromise<AdjustmentsPage> {
  const searchPromise = searchByUrl(link.href, criteria);
  return new CancelablePromise<AdjustmentsPage>(searchPromise);
}

export default {
  getDisbursementByUrl,
  getDisbursementsViewAndSummaries,
  search,
};
