import Navigation from '../../shared/models/Navigation';
import PageMetadata from '../../shared/models/PageMetadata';
import Adjustment from './Adjustment';

export default class AdjustmentsPage {
  adjustments: Adjustment[];
  navigation: Navigation;
  metadata: PageMetadata;

  constructor(adjustments: Adjustment[], navigation: Navigation, metadata: PageMetadata) {
    this.adjustments = adjustments;
    this.navigation = navigation;
    this.metadata = metadata;
  }
}
