import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import './index.css';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import * as serviceWorker from './serviceWorker';
import App from './App';
import Logout from './logout/Logout';

const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const oidcConfig = {
  authority: process.env.REACT_APP_AUTHORITY,
  automaticSilentRenew: false,
  client_id: process.env.REACT_APP_CLIENT_ID,
  response_type: 'code',
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  post_logout_redirect_uri: `${process.env.REACT_APP_REDIRECT_URL}#/logout`,
  scope: process.env.REACT_APP_SCOPES,
  revokeTokensOnSignout: true,
  onSigninCallback,
};

ReactDOM.render(
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AuthProvider {...oidcConfig}>
    <Router>
      <Switch>
        <Route path="/logout" component={Logout} />
        <Route component={App} />
      </Switch>
    </Router>
  </AuthProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
