export const MY_PAY_BY_DATE_RANGE = 'MY_PAY_BY_DATE_RANGE';
export const ALL_BY_PAID_DATE = 'ALL_BY_PAID_DATE';
export const MY_PAY = 'MY_PAY';
export const PAY_ASSIGNED_TO_OTHER = 'PAY_ASSIGNED_TO_OTHER';
export const SUPPORTING_DETAILS = 'SUPPORTING_DETAILS';
export const ALL_BY_DISBURSEMENT = 'ALL_BY_DISBURSEMENT';
export const ALL_BY_ACCOUNT_NUMBER = 'ALL_BY_ACCOUNT_NUMBER';

const OptionsKeys = [
  MY_PAY,
  PAY_ASSIGNED_TO_OTHER,
  SUPPORTING_DETAILS,
  ALL_BY_DISBURSEMENT,
  ALL_BY_PAID_DATE,
  MY_PAY_BY_DATE_RANGE,
  ALL_BY_ACCOUNT_NUMBER,
] as const;

export type OptionKey = typeof OptionsKeys[number];

export default interface BasicSearchOption {
  key: OptionKey;
  label: string;
  tooltipText: string;
}
