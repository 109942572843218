import { useCallback, useEffect, useState } from 'react';
import Table from '../../shared/components/Table/Table';
import SelectorContainer from '../preference-selectors/SelectorContainer';
import Column from '../../shared/models/Column';
import Link from '../../shared/models/Link';
import './EarningsTable.css';
import EarningFilters from '../filters/EarningFilters/EarningFilters';
import EarningsApi from '../../api/earnings-api';
import Earning from '../models/Earning';
import AdvancedSearchCriteria from '../../search/models/AdvancedSearchCriteria';
import EarningsPage from '../models/EarningsPage';
import CancelablePromise from '../../util/CancelablePromise';
import { useAdvancedSearchCriteria } from '../../search/hooks/useAdvancedSearchCriteria';
import FilterOption from '../models/FilterOption';
import { useUserPreferences } from '../../user-preferences/UserPreferencesProvider';
import UserPreferences from '../../user-preferences/UserPreferences';
import { useCurrentSearchType } from '../../search/hooks/useEarningsSearchProvider';
import { useBasicSearchCriteria } from '../../search/hooks/useBasicSearchCriteria';

interface Props {
  initialPageEarningsLink: Link | undefined;
  columnDefinitions: Column[];
  commissionTypeFilterOptions: FilterOption[];
  compensationTypeFilterOptions: FilterOption[];
  compensationEventTypeFilterOptions: FilterOption[];
  premiumTypeFilterOptions: FilterOption[];
  defaultColumns: string[];
}

const categorizeColumns = (userPreferences: UserPreferences, columnDefinitions: Column[]) => {
  const newColumns: Column[] = [];

  userPreferences.selectedColumns.forEach((selectedColumn) => {
    const newColumn = columnDefinitions.find((c) => c.id === selectedColumn);
    const sortedColumn = userPreferences.sortedColumns?.find((c) => c.id === selectedColumn);
    if (newColumn) {
      newColumns.push({
        ...newColumn,
        ...sortedColumn,
        show: true,
        category: 'Default',
        isSorted: !!sortedColumn,
      });
    }
  });

  return newColumns;
};

const EarningsTable = (props: Props) => {
  const {
    initialPageEarningsLink,
    columnDefinitions,
    commissionTypeFilterOptions,
    compensationTypeFilterOptions,
    compensationEventTypeFilterOptions,
    premiumTypeFilterOptions,
    defaultColumns,
  } = props;
  const [columns, setColumns] = useState<Column[]>(columnDefinitions);
  const [nextPageEarningsLink, setNextPageEarningsLink] = useState<Link | undefined>(undefined);
  const [earnings, setEarnings] = useState<Earning[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [commissionTypesCriteria, setCommissionTypesCriteria] = useState<AdvancedSearchCriteria['commissionTypes']>();
  const [compensationTypesCriteria, setCompensationTypesCriteria] =
    useState<AdvancedSearchCriteria['compensationTypes']>();
  const [compensationEventTypesCriteria, setCompensationEventTypesCriteria] =
    useState<AdvancedSearchCriteria['compensationEventTypes']>();
  const [premiumTypesCriteria, setPremiumTypesCriteria] = useState<AdvancedSearchCriteria['premiumTypes']>();
  const [searchPromise, setSearchPromise] = useState<CancelablePromise<EarningsPage> | undefined>();
  const [isLoadingEarnings, setIsLoadingEarnings] = useState<boolean>(true);
  const advancedSearchCriteria = useAdvancedSearchCriteria();
  const basicSearchCriteria = useBasicSearchCriteria();
  const { userPreferences, dispatch } = useUserPreferences();
  const searchType = useCurrentSearchType();

  useEffect(() => {
    setHasMore(nextPageEarningsLink !== undefined);
  }, [setHasMore, nextPageEarningsLink]);

  useEffect(() => {
    setHasMore(false);
    setNextPageEarningsLink(undefined);
    setEarnings([]);
    if (searchPromise) {
      searchPromise.cancel();
    }

    const fetchNewEarnings = (earningsLink: Link) => {
      setIsLoadingEarnings(true);

      const promise = EarningsApi.search(earningsLink, {
        ...(searchType === 'advanced' ? advancedSearchCriteria : basicSearchCriteria),
        commissionTypes: commissionTypesCriteria,
        compensationTypes: compensationTypesCriteria,
        compensationEventTypes: compensationEventTypesCriteria,
        premiumTypes: premiumTypesCriteria,
        includeZeroDollarCompensationAmount: userPreferences?.includeZeroDollarCompensationAmount,
      });

      setSearchPromise(promise);

      promise.promise
        .then((newEarningsPage) => {
          setNextPageEarningsLink(newEarningsPage.navigation.next);
          setEarnings((previousEarnings) => [...previousEarnings, ...newEarningsPage.earnings]);
          setIsLoadingEarnings(false);
        })
        .catch(() => {});
    };

    const lookupColumnDefinitionAccessor = (columnId: string) =>
      columnDefinitions.find((columnDefinition) => columnDefinition.id === columnId)?.accessor;

    if (initialPageEarningsLink && userPreferences) {
      const url = new URL(initialPageEarningsLink.href);
      if (userPreferences.sortedColumns) {
        userPreferences.sortedColumns
          .filter((col) => col.sortDirection !== undefined)
          .map((col) => `${lookupColumnDefinitionAccessor(col.id)},${col.sortDirection}`)
          .forEach((column) => {
            url.searchParams.append('sort', column);
          });
      }

      fetchNewEarnings(new Link(unescape(url.href)));
    }
    // eslint-disable-next-line
  }, [
    initialPageEarningsLink,
    commissionTypesCriteria,
    compensationTypesCriteria,
    compensationEventTypesCriteria,
    premiumTypesCriteria,
    userPreferences?.sortedColumns,
    userPreferences?.includeZeroDollarCompensationAmount,
  ]);

  useEffect(() => {
    if (userPreferences) {
      const newColumns = categorizeColumns(userPreferences, columnDefinitions);
      setColumns(newColumns);
    }
  }, [userPreferences, columnDefinitions, setColumns]);

  const sortHandler = useCallback(
    async (newSortedColumn: Column) => {
      if (userPreferences && dispatch) {
        const sortedColumns = userPreferences.sortedColumns || [];
        const previousSortedColumns = [...sortedColumns];
        const newSortedColumns: Column[] = [];
        previousSortedColumns.forEach((c) => {
          const columnDefinition = columnDefinitions.find((cd) => cd.id === c.id);
          if (columnDefinition) {
            newSortedColumns.push({
              ...columnDefinition,
              sortDirection: c.sortDirection,
              isSorted: true,
            });
          }
        });
        // update new sorted columns with the new sorted column
        const columnWasPreviouslySorted = newSortedColumns.some((c) => c.id === newSortedColumn.id);
        if (columnWasPreviouslySorted) {
          const index = newSortedColumns.findIndex((c) => c.id === newSortedColumn.id);
          newSortedColumns.splice(index, 1, newSortedColumn);
        } else {
          newSortedColumns.push(newSortedColumn);
        }

        dispatch({
          type: 'sort-column',
          payload: { sortedColumns: newSortedColumns.filter((c) => c.sortDirection !== undefined) },
        });
      }
    },
    [userPreferences, columnDefinitions, dispatch],
  );

  const loadMoreEarnings = () => {
    if (nextPageEarningsLink) {
      const promise = EarningsApi.search(nextPageEarningsLink, {
        ...(searchType === 'advanced' ? advancedSearchCriteria : basicSearchCriteria),
        commissionTypes: commissionTypesCriteria,
        compensationTypes: compensationTypesCriteria,
        compensationEventTypes: compensationEventTypesCriteria,
        premiumTypes: premiumTypesCriteria,
        includeZeroDollarCompensationAmount: userPreferences?.includeZeroDollarCompensationAmount,
      });

      promise.promise.then((newEarningPage) => {
        setNextPageEarningsLink(newEarningPage.navigation.next);
        setEarnings((previousEarnings) => [...previousEarnings, ...newEarningPage.earnings]);
      });
    }
  };

  const onColumnReorder = async (columnIds: string[]) => {
    const reorderedColumns: Column[] = [];
    columnIds.forEach((id) => {
      const column = columnDefinitions.find((c) => c.id === id);
      if (column) {
        reorderedColumns.push(column);
      }
    });

    if (dispatch) {
      dispatch({ type: 'columns-reordered', payload: { selectedColumns: reorderedColumns } });
    }
  };

  return (
    <div id="EarningsTable">
      <div className="row">
        <div className="col-md-2 no-print row">
          <SelectorContainer columnDefinitions={columnDefinitions} defaultColumns={defaultColumns} />
        </div>
        <div
          className={
            !userPreferences?.columnSelectionCollapsed ? 'col-md-10 table-overflow' : 'col-md-12 table-overflow'
          }
        >
          <div className="row">
            <div className="col-md-12">
              <EarningFilters
                onChange={setCompensationTypesCriteria}
                filterOptions={compensationTypeFilterOptions}
                eventLabelText="Compensation Type Filter"
              />
              <EarningFilters
                onChange={setCommissionTypesCriteria}
                filterOptions={commissionTypeFilterOptions}
                eventLabelText="Commission Type Filter"
              />
              <EarningFilters
                onChange={setCompensationEventTypesCriteria}
                filterOptions={compensationEventTypeFilterOptions}
                eventLabelText="Compensation Event Filter"
              />
              <EarningFilters
                onChange={setPremiumTypesCriteria}
                filterOptions={premiumTypeFilterOptions}
                eventLabelText="Premium Type Filter"
              />
            </div>
            <div>
              <Table
                earnings={earnings}
                hasMore={hasMore}
                loadMore={loadMoreEarnings}
                columns={columns}
                sortHandler={sortHandler}
                onColumnReorder={onColumnReorder}
                isLoadingContent={isLoadingEarnings}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EarningsTable;
