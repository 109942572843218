import React from 'react';
import Alert from '../../shared/components/Alert/Alert';

export interface AccessRemovedAlertProps {
  hasAccessRemoved?: boolean;
}

const AccessRemovedAlert: React.FC<AccessRemovedAlertProps> = ({ hasAccessRemoved }) => {
  if (!hasAccessRemoved) {
    return null;
  }

  return (
    <div className="no-print util-padding-left-50 util-padding-right-50">
      <Alert
        severity="danger"
        message={
          'There is a problem with your security which may temporarily limit ' +
          'your compensation reporting access. Principal staff have been notified.'
        }
      />
    </div>
  );
};
export default AccessRemovedAlert;
