import { useEffect, useState } from 'react';
import './ToggleDropdownSelector.css';
import AdvancedSearchOption from '../models/AdvancedSearchOption';

interface Props {
  options: AdvancedSearchOption[];
  onChange: (hasLabel: AdvancedSearchOption) => void;
  buttonClasses?: string;
}

const ToggleDropdownSelector = (props: Props) => {
  const { options, onChange, buttonClasses = '' } = props;
  const [selectedOption, setSelectedOption] = useState<AdvancedSearchOption>(options[0] || '');

  const createOnClick = (option: AdvancedSearchOption) => (event: React.MouseEvent) => {
    event.preventDefault();
    setSelectedOption(option);
  };

  useEffect(() => {
    onChange(selectedOption);
  }, [selectedOption, onChange]);

  return (
    <div className="input-group-btn">
      <button
        type="button"
        className={`btn btn-primary dropdown-toggle search-option-dropdown ${buttonClasses}`}
        data-toggle="dropdown"
      >
        {selectedOption.label}
        <i className="fa fa-angle-down util-float-right" />
      </button>
      <ul className="dropdown-menu dropdown-menu-right" role="menu">
        {options &&
          options.map((option) => (
            <li key={option.label}>
              <a href="# " onClick={createOnClick(option)}>
                {option.label}
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ToggleDropdownSelector;
