import DOMPurify from 'dompurify';

const sanitize = (value: string) => {
  const domPurifyConfig = {
    USE_PROFILES: { html: true },
    ALLOWED_TAGS: ['a', 'br', 'em', 'li', 'ol', 'p', 'strong', 'u', 'ul'],
    ALLOWED_ATTR: ['href'],
    ALLOWED_URI_REGEXP: /^https:/i,
  };

  return DOMPurify.sanitize(value, domPurifyConfig);
};

export default sanitize;
