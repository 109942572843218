import Formatter from '../../util/formatter';

interface Prop {
  amount: number | undefined;
  label: string;
}

const DataCard = (props: Prop) => {
  const { amount, label } = props;
  return (
    <div className="data-card-contents">
      <h1 className="data-card-amount">
        <span className="data-card-currency">$</span>
        {Formatter.numberToUSD(amount)}
      </h1>
      <h3 className="data-card-label">{label}</h3>
    </div>
  );
};

export default DataCard;
