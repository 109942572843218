import { FieldError } from '../../../api/ApiError';

export interface AlertProps {
  severity: 'danger' | 'warning' | 'info' | 'success';
  message: string;
  errorList?: FieldError[];
}

const Alert = (props: AlertProps) => {
  const { severity, message, errorList } = props;
  return (
    <div className={`alert alert-${severity} alert-icon`} role="alert">
      {message}
      {errorList && (
        <ul>
          {errorList.map((e: FieldError) => (
            <li key={e.field}>{e.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Alert;
