import { useEffect, useState } from 'react';
import 'react-dates/initialize';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import moment, { Moment } from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import './DatePicker.css';

interface Props {
  startDate?: Date;
  endDate?: Date;
  isDisabled?: boolean;
  onChange: (startDate: Date | undefined, endDate: Date | undefined) => void;
  showIcon?: boolean;
}

interface Dates {
  startDate: Moment | null;
  endDate: Moment | null;
}

export const DatePicker = (props: Props) => {
  const { onChange, isDisabled, startDate, endDate, showIcon } = props;

  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);
  const [currentStartDate, setCurrentStartDate] = useState<Moment | null>(null);
  const [currentEndDate, setCurrentEndDate] = useState<Moment | null>(null);

  useEffect(() => {
    setCurrentStartDate(startDate ? moment(startDate) : null);
    setCurrentEndDate(endDate ? moment(endDate) : null);
  }, [startDate, endDate]);

  const onDatesChange = ({ startDate: newStartDate, endDate: newEndDate }: Dates) => {
    setCurrentStartDate(newStartDate);
    setCurrentEndDate(newEndDate);

    if (newStartDate && newEndDate) {
      onChange(newStartDate?.toDate(), newEndDate?.toDate());
    }
  };

  const onFocusChange = (newFocusedInput: 'startDate' | 'endDate' | null) => {
    setFocusedInput(newFocusedInput);
  };

  return (
    <DateRangePicker
      startDate={currentStartDate}
      endDate={currentEndDate}
      onDatesChange={onDatesChange}
      startDateId="startDateId"
      endDateId="endDateId"
      focusedInput={focusedInput}
      onFocusChange={onFocusChange}
      minimumNights={0}
      block
      isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
      disabled={isDisabled}
      numberOfMonths={1}
      showDefaultInputIcon={showIcon}
    />
  );
};

DatePicker.defaultProps = {
  isDisabled: false,
};

export default DatePicker;
