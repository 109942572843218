import HubFetch from './hub-fetch';
import ApiError from './ApiError';

// eslint-disable-next-line import/prefer-default-export
export async function sendFeedback(feedbackUrl: string, feedback: string): Promise<string> {
  const method = 'POST';
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  const body = JSON.stringify({ body: feedback });
  const init: RequestInit = { method, headers, body };

  try {
    return await HubFetch.hubFetch(feedbackUrl, init);
  } catch (e) {
    const { status, errors } = e as ApiError;
    throw new ApiError('Unable to submit feedback.', status, errors);
  }
}
