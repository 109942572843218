import { RichUtils } from 'draft-js';
import React from 'react';
import { RichTextAreaToolbarProps, StyleButtonProps } from './RichContentEditableToolbar';

const InlineStyleButton: React.FC<StyleButtonProps> = (props) => {
  const { styleName, editorState, onContentChange, disabled } = props;

  const toggleInlineStyle = (inlineStyle: string) => {
    onContentChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };
  const includesInlineStyle = (inlineStyle: string): boolean =>
    editorState.getCurrentInlineStyle().toList().toArray().includes(inlineStyle);
  return (
    <button
      type="button"
      className={`btn StyleButtons-button ${includesInlineStyle(styleName) ? 'active' : ''} `}
      onClick={() => toggleInlineStyle(styleName)}
      aria-label={`${styleName.toLowerCase()} button`}
      title={styleName.toLowerCase()}
      disabled={disabled}
      tabIndex={-1}
    >
      <i className={`fa fa-${styleName.toLowerCase()}`} />
    </button>
  );
};

const InlineStyleButtons: React.FC<RichTextAreaToolbarProps> = (props) => {
  const { editorState, onContentChange, disabled } = props;
  const inlineStyleButtons = ['BOLD', 'ITALIC', 'UNDERLINE'];

  return (
    <div className="btn-group btn-group-sm" role="group">
      {inlineStyleButtons.map((inlineStyleName) => (
        <InlineStyleButton
          key={`InlineStyleButton-${inlineStyleName}`}
          styleName={inlineStyleName}
          editorState={editorState}
          onContentChange={onContentChange}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default InlineStyleButtons;
