import { useEffect, useState } from 'react';
import Notification from './Notification/Notification';
import NotificationType from './models/Notification';
import { getActiveNotificationsForUser } from '../api/notifications-api';

interface Props {
  notificationHref: string;
}

const Notifications = (props: Props) => {
  const { notificationHref } = props;
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const newNotifications = await getActiveNotificationsForUser(notificationHref);
      setNotifications(newNotifications);
    };

    fetchNotifications();
  }, [notificationHref]);

  function getBodyFromNotification(notification: NotificationType) {
    if (notification.bodyAsHtml) {
      return notification.bodyAsHtml;
    }
    return notification.bodyAsText ?? '';
  }

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="notification col-md-8 col-md-offset-2">
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              id={notification.id}
              title={notification.title}
              body={getBodyFromNotification(notification)}
              notificationLevel={notification.notificationLevel}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
