import { useCallback, useEffect, useState } from 'react';
import './AdjustmentsTable.css';
import Link from '../../shared/models/Link';
import Column from '../../shared/models/Column';
import Table from '../../shared/components/Table/Table';
import Adjustment from '../models/Adjustment';
import AdjustmentsPage from '../models/AdjustmentsPage';
import CancelablePromise from '../../util/CancelablePromise';
import { useAdvancedSearchCriteria } from '../../search/hooks/useAdvancedSearchCriteria';
import AdjustmentsApi from '../../api/adjustments-api';

interface Props {
  adjustmentsPageLink: Link | undefined;
  columnDefinitions: Column[];
}

const AdjustmentsTable = (props: Props) => {
  const { adjustmentsPageLink, columnDefinitions } = props;
  const [columns] = useState<Column[]>(columnDefinitions);
  const [adjustments, setAdjustments] = useState<Adjustment[]>([]);
  const [searchPromise, setSearchPromise] = useState<CancelablePromise<AdjustmentsPage> | undefined>();
  const [isLoadingAdjustments, setIsLoadingAdjustments] = useState<boolean>(true);
  const advancedSearchCriteria = useAdvancedSearchCriteria();

  useEffect(() => {
    setAdjustments([]);
    if (searchPromise) {
      searchPromise.cancel();
    }

    const fetchNewAdjustments = (adjustmentsLink: Link) => {
      setIsLoadingAdjustments(true);

      const promise = AdjustmentsApi.search(adjustmentsLink, {
        ...advancedSearchCriteria,
        includeZeroDollarCompensationAmount: true,
      });

      setSearchPromise(promise);

      promise.promise
        .then((newAdjustmentsPage) => {
          setAdjustments((previousAdjustments) => [...previousAdjustments, ...newAdjustmentsPage.adjustments]);
          setIsLoadingAdjustments(false);
        })
        .catch(() => {});
    };

    if (adjustmentsPageLink) {
      const url = new URL(adjustmentsPageLink.href);
      fetchNewAdjustments(new Link(unescape(url.href)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustmentsPageLink]);

  const sortHandler = useCallback(async (newSortedColumn: Column) => {
    setAdjustments((previousAdjustments) => {
      const sortedAdjustments = [...previousAdjustments].sort((a, b) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        a[newSortedColumn.accessor as keyof typeof a]! < b[newSortedColumn.accessor as keyof typeof b]! ? -1 : 1,
      );
      if (newSortedColumn.sortDirection === 'desc') {
        sortedAdjustments.reverse();
      }
      return sortedAdjustments;
    });
  }, []);

  const onColumnReorder = async (columnIds: string[]) => {
    const reorderedColumns: Column[] = [];
    columnIds.forEach((id) => {
      const column = columnDefinitions.find((c) => c.id === id);
      if (column) {
        reorderedColumns.push(column);
      }
    });
  };

  return (
    <div id="AdjustmentsTable">
      <div className="row">
        <div className="col-md-12 earning-table-container">
          <div>
            <Table
              adjustments={adjustments}
              hasMore={false}
              loadMore={() => {}}
              columns={columns}
              sortHandler={sortHandler}
              onColumnReorder={onColumnReorder}
              isLoadingContent={isLoadingAdjustments}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdjustmentsTable;
