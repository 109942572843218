import Link from '../shared/models/Link';
import HubFetch from './hub-fetch';
import UserPreferences from '../user-preferences/UserPreferences';

const headers = new Headers();
headers.set('Content-Type', 'application/json');

export async function get(url: Link): Promise<UserPreferences> {
  const response = await HubFetch.hubFetch(url.href);
  return JSON.parse(response);
}

export async function save(preferences: UserPreferences) {
  const method = 'POST';
  const body = JSON.stringify(preferences);
  await HubFetch.hubFetch(preferences._links.save.href, { method, headers, body });
}

export default {
  get,
  save,
};
