import LoadingSpinner from '../shared/components/Loading/LoadingSpinner';
import SecurityProfile from './models/SecurityProfile';

interface Props {
  isLoading: boolean;
  securityProfiles: SecurityProfile[];
}

const SecurityProfilesTable = (props: Props) => {
  const { securityProfiles, isLoading } = props;
  return securityProfiles?.length > 0 || isLoading ? (
    <table className="table table-hover">
      <thead>
        <tr>
          <th>Enterprise Id</th>
          <th>Authorized to View</th>
          <th>Office/Unit Number</th>
          <th>Product Lines</th>
          <th>Compensation Type</th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={5}>
              <LoadingSpinner />
            </td>
          </tr>
        ) : (
          securityProfiles?.map((securityProfile) =>
            securityProfile.securityProfileAuthorizations?.map((authorization) =>
              authorization.permissions.map((permission) => (
                <tr key={`${securityProfile.enterpriseId}${authorization.subject}${permission.products}`}>
                  <td>{securityProfile.enterpriseId}</td>
                  <td>{`${authorization.name} (${authorization.subject})`}</td>
                  <td>{authorization.officeId}</td>
                  <td>{permission.products.join(', ')}</td>
                  <td>{permission.actions.join(', ')}</td>
                </tr>
              )),
            ),
          )
        )}
      </tbody>
    </table>
  ) : (
    <div />
  );
};

export default SecurityProfilesTable;
