import AdvancedSearchCriteria, { EMPTY_SEARCH_CRITERIA } from '../search/models/AdvancedSearchCriteria';
import EarningsPage from '../earnings/models/EarningsPage';
import PageMetadata from '../shared/models/PageMetadata';
import Link from '../shared/models/Link';
import HubFetch from './hub-fetch';
import CancelablePromise from '../util/CancelablePromise';
import Navigation from '../shared/models/Navigation';
import BasicSearchCriteria from '../search/models/BasicSearchCriteria';

const headers = new Headers({ 'Content-Type': 'application/json' });
const csvHeaders = new Headers({ Accept: 'text/csv', 'Content-Type': 'application/json' });

async function searchByUrl(
  endpoint: string,
  criteria: AdvancedSearchCriteria | BasicSearchCriteria,
): Promise<EarningsPage> {
  const method = 'POST';
  const body = JSON.stringify(criteria);

  try {
    const text = await HubFetch.hubFetch(endpoint, { method, headers, body });
    const earningsPayload = JSON.parse(text);

    let earningsList = [];
    if (earningsPayload._embedded && earningsPayload._embedded.earningList) {
      earningsList = earningsPayload._embedded.earningList;
    }

    const page = new PageMetadata(
      earningsPayload.page.number,
      earningsPayload.page.size,
      earningsPayload.page.totalElements,
      earningsPayload.page.totalPages,
    );

    return new EarningsPage(earningsList, earningsPayload._links, page);
  } catch (e) {
    return new EarningsPage([], {} as Navigation, new PageMetadata(0, 0, 0, 0));
  }
}

function search(
  link: Link,
  criteria: BasicSearchCriteria | AdvancedSearchCriteria = EMPTY_SEARCH_CRITERIA,
): CancelablePromise<EarningsPage> {
  const searchPromise = searchByUrl(link.href, criteria);
  return new CancelablePromise<EarningsPage>(searchPromise);
}

async function getFilterOptions(link: Link) {
  try {
    const text = await HubFetch.hubFetch(link.href);
    return JSON.parse(text);
  } catch (e) {
    return [];
  }
}

async function findEarningsByUrlAsCsv(url: Link, filename: string, searchCriteria: BasicSearchCriteria): Promise<void> {
  const response = await HubFetch.hubFetch(url.href, {
    headers: csvHeaders,
    method: 'POST',
    body: JSON.stringify(searchCriteria),
  });
  const blob = new Blob([response], { type: 'text/csv' });
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove(); // afterwards we remove the element again
}

export default {
  search,
  getFilterOptions,
  findEarningsByUrlAsCsv,
};
