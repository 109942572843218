import useSavedSearches from '../../hooks/useSavedSearches';
import SavedSearch from '../../models/SavedSearch';
import { useAdvancedSearchCriteriaUpdater } from '../../hooks/useAdvancedSearchCriteria';
import useDeleteSavedSearch from '../../hooks/useDeleteSavedSearch';

const SavedSearchListItems = () => {
  const { data: savedSearches } = useSavedSearches();
  const updateSearchCriteria = useAdvancedSearchCriteriaUpdater();
  const [deleteById] = useDeleteSavedSearch();

  const onSavedSearchClick = (savedSearch: SavedSearch, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    updateSearchCriteria(savedSearch.searchCriteria);
  };

  const onDeleteSearchClick = async (id: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    await deleteById(id);
  };

  if (savedSearches === undefined) {
    return <div />;
  }

  return (
    <>
      <li className="dropdown-header">Apply Saved Search</li>
      {savedSearches.map((s) => (
        <li key={s.id}>
          <button
            type="button"
            className="btn btn-link btn-link-inline text-muted pull-right"
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onDeleteSearchClick(s.id, event)}
          >
            <i className="fa fa-trash" />
          </button>
          <a
            href="# "
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => onSavedSearchClick(s, event)}
          >
            {s.title}
          </a>
        </li>
      ))}
    </>
  );
};

export default SavedSearchListItems;
