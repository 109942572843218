import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { EditorState, RichUtils } from 'draft-js';
import { Overlay, Popover } from 'react-bootstrap';
import { RichTextAreaToolbarProps } from './RichContentEditableToolbar';

const LinkStyleButtons: React.FC<RichTextAreaToolbarProps> = (props) => {
  const { editorState, onContentChange, disabled } = props;
  const [showUrlInput, setShowUrlInput] = useState<boolean>(false);
  const [urlValue, setUrlValue] = useState<string>('');
  const validLink = useMemo<boolean>(() => Boolean(urlValue) && urlValue.startsWith('https://'), [urlValue]);

  const urlRef = useRef<HTMLInputElement>(null);
  const createLinkButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (showUrlInput) {
      urlRef.current?.focus();
      setTimeout(() => {
        urlRef.current?.select();
      }, 0);
    }
  }, [showUrlInput]);

  const confirmLink = (event: MouseEvent | undefined, action: string | undefined) => {
    if (event) {
      event.preventDefault();
    }

    if (action === 'OK' && validLink) {
      const contentStateWithEntity = editorState.getCurrentContent().createEntity('LINK', 'MUTABLE', { url: urlValue });
      const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

      onContentChange(
        RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          contentStateWithEntity.getLastCreatedEntityKey(),
        ),
      );
    }

    setShowUrlInput(false);
    setUrlValue('');
  };

  const onLinkInputKeyDown = (event: React.KeyboardEvent<HTMLElement>, action: string) => {
    if (event.key === 'Enter' && validLink) {
      event.preventDefault();
      confirmLink(undefined, action);
    } else if (event.key === 'Escape') {
      event.preventDefault();
      confirmLink(undefined, 'CANCEL');
    }
  };

  const onUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUrlValue(event.target.value);
  };

  const removeLink = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      onContentChange(RichUtils.toggleLink(editorState, selection, null));
    }
  };

  const promptForLink = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const otherContentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = otherContentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = '';
      if (linkKey) {
        const linkInstance = otherContentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }

      setShowUrlInput(true);
      setUrlValue(url);
    }
  };

  const onLinkFocus = () => {
    if (!urlValue) {
      setUrlValue('https://');
    }
  };

  const popover = (
    <Popover id="popover-contained">
      <div className="popover-container">
        <div className="input-group input-group-sm">
          <input
            id="url"
            type="url"
            pattern="https://.*"
            title='link must begin with "https://"'
            ref={urlRef}
            value={urlValue}
            className="form-control popover-input"
            placeholder="https://example.com"
            onChange={(e) => onUrlChange(e)}
            onKeyDown={(e) => onLinkInputKeyDown(e, 'OK')}
            onFocus={onLinkFocus}
          />
          <span
            role="button"
            aria-label="Create Link"
            tabIndex={0}
            className={`input-group-addon ${validLink ? 'ok-button' : 'cursor-not-allowed'}`}
            onClick={(e) => confirmLink(e, 'OK')}
            onKeyDown={(e) => onLinkInputKeyDown(e, 'OK')}
            title={validLink ? '' : 'link must begin with "https://"'}
          >
            <i className={`fa fa-check ${validLink ? 'text-success' : 'text-disabled'}`} />
          </span>
          <span
            role="button"
            aria-label="Discard Link"
            tabIndex={0}
            className="input-group-addon cancel-button"
            onClick={(e) => confirmLink(e, 'CANCEL')}
            onKeyDown={(e) => onLinkInputKeyDown(e, 'CANCEL')}
          >
            <i className="fa fa-times text-danger" />
          </span>
        </div>
      </div>
    </Popover>
  );

  return (
    <div className="btn-group btn-group-sm" role="group">
      <button
        ref={createLinkButtonRef}
        type="button"
        className="btn StyleButtons-button"
        aria-label="create hyperlink"
        onMouseDown={(e) => promptForLink(e)}
        disabled={disabled}
        tabIndex={-1}
      >
        <i className="fa fa-link" />
      </button>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Overlay
        show={showUrlInput}
        onHide={() => {}}
        onEntered={() => urlRef.current?.focus()}
        placement="bottom"
        target={() => createLinkButtonRef.current}
        rootClose
      >
        {popover}
      </Overlay>
      <button
        type="button"
        className="btn StyleButtons-button "
        aria-label="remove hyperlink"
        onMouseDown={(e) => removeLink(e)}
        disabled={disabled}
        tabIndex={-1}
      >
        <i className="fa fa-unlink" />
      </button>
    </div>
  );
};

export default LinkStyleButtons;
