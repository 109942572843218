import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Link from '../../shared/models/Link';
import { getNotification, updateNotification } from '../../api/notifications-api';
import NotificationForm from '../create/NotificationForm';
import { NotificationRequest } from '../models/NotificationRequest';
import Notification from '../models/Notification';

interface Props {
  notificationsLink: Link;
}

interface RouteParams {
  id: string;
}

const EditNotification = (props: Props) => {
  const { notificationsLink } = props;
  const history = useHistory();
  const { id } = useParams<RouteParams>();

  const [notification, setNotification] = useState<Notification>();

  const onSubmit = async (updateRequest: NotificationRequest) => {
    await updateNotification({ href: `${notificationsLink.href}/${id}` }, updateRequest);
    history.push('/operations/notifications');
  };

  useEffect(() => {
    if (id !== undefined) {
      const init = async () => {
        const currentNotification = await getNotification({
          href: `${notificationsLink.href}/${id}`,
        });
        setNotification(currentNotification);
      };

      init();
    }
  }, [id, notificationsLink.href]);

  return (
    <>
      <h1>Edit Notification</h1>
      <NotificationForm onSubmit={onSubmit} notification={notification} />
    </>
  );
};

export default EditNotification;
