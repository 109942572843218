import React from 'react';
import ReactLoadingOverlay from 'react-loading-overlay';
import LoadingSpinner from './LoadingSpinner';

interface OverlayProps {
  isActive: boolean;
  children?: React.ReactNode;
}

const LoadingOverlay: React.FC<OverlayProps> = (props) => {
  const { isActive, children } = props;
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ReactLoadingOverlay active={isActive} spinner={<LoadingSpinner />} text="Hang on a moment...">
      {children}
    </ReactLoadingOverlay>
  );
};

export default LoadingOverlay;
