import SavedSearchMenu from './SavedSearchMenu';

const SavedSearchDropDown = () => (
  <>
    <button
      type="button"
      className="btn btn-primary dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i className="fa fa-angle-down" />
      <span className="sr-only">Toggle Dropdown</span>
    </button>
    <SavedSearchMenu />
  </>
);

export default SavedSearchDropDown;
