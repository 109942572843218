/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import moment from 'moment';
import ToggleDropdownSelector from '../../ToggleDropdownSelector/ToggleDropdownSelector';
import AdvancedSearchCriteria from '../../models/AdvancedSearchCriteria';
import AdvancedSearchOption from '../../models/AdvancedSearchOption';
import '../Search/Search.css';
import DatePicker from '../../DatePicker/DatePicker';
import {
  useAdvancedSearchCriteria,
  useAdvancedSearchCriteriaUpdater,
  useAdvancedSearchOptions,
} from '../../hooks/useAdvancedSearchCriteria';
import '../SavedSearchDropDown/SavedSearchMenu.css';
import SavedSearchDropDown from '../SavedSearchDropDown/SavedSearchDropDown';
import SearchPills from '../SearchPills/SearchPills';

const formatDateIso = (date: Date | undefined) => moment(date).format('YYYY-MM-DD');

const AdvancedSearchInputGroup = () => {
  const [searchInputText, setSearchInputText] = useState<string>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [startDateText, setStartDateText] = useState<string>();
  const [endDateText, setEndDateText] = useState<string>();
  const [currentField, setCurrentField] = useState<AdvancedSearchOption>();

  const searchCriteria: AdvancedSearchCriteria | undefined = useAdvancedSearchCriteria();
  const updateSearchCriteria = useAdvancedSearchCriteriaUpdater();
  const searchOptions = useAdvancedSearchOptions();

  const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputText(event.target.value);
  };

  const onDateChange = (newStartDate: Date | undefined, newEndDate: Date | undefined) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    const newStartDateText = formatDateIso(newStartDate);
    const newEndDateText = formatDateIso(newEndDate);
    setStartDateText(newStartDateText);
    setEndDateText(newEndDateText);
    setSearchInputText(`${newStartDate} - ${newEndDate}`);
  };

  const applySearch = () => {
    const key = currentField?.key;
    const label = currentField?.label;
    if (searchInputText && key && label) {
      const newSearchCriteria: AdvancedSearchCriteria = { ...searchCriteria };

      if (key === 'paidDate') {
        newSearchCriteria.startDate = startDateText;
        newSearchCriteria.endDate = endDateText;
      } else {
        const previousSearchValue: string[] = (searchCriteria as any)[key] || [];
        if (!previousSearchValue.includes(searchInputText)) {
          (newSearchCriteria as any)[key] = [...previousSearchValue, searchInputText.trim()];
        }
      }

      setSearchInputText('');
      setStartDate(undefined);
      setEndDate(undefined);
      updateSearchCriteria(newSearchCriteria);
    }
  };

  const onFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSearchInputText(searchInputText);
    applySearch();
  };

  const placeholder = `Filter on: ${currentField?.label}`;
  return (
    <>
      <form onSubmit={onFormSubmit} className="input-suffix">
        <div className="row">
          <div className="col-md-10">
            <div className="input-group">
              {searchOptions && <ToggleDropdownSelector options={searchOptions} onChange={setCurrentField} />}
              {currentField?.key !== 'paidDate' ? (
                <input
                  id="search"
                  placeholder={placeholder}
                  type="text"
                  value={searchInputText || ''}
                  className="form-control"
                  onChange={onSearchInputChange}
                />
              ) : (
                <DatePicker
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onDateChange}
                  isDisabled={searchCriteria?.startDate !== undefined && searchCriteria.endDate !== undefined}
                />
              )}
              <span className="suffix magnify">
                <i className="fa fa-search text-primary" />
              </span>
            </div>
          </div>
          <div className="col-md-2">
            <div className="btn-group">
              <button type="button" className="btn btn-primary apply-filter" onClick={applySearch}>
                Apply Filter
              </button>
              <SavedSearchDropDown />
            </div>
          </div>
        </div>
      </form>
      <SearchPills />
    </>
  );
};

export default AdvancedSearchInputGroup;
