import { User } from 'oidc-client-ts';
import ApiError from './ApiError';

const hubFetch = async (request: string | Request, init?: RequestInit): Promise<string> => {
  const defaultInit: RequestInit = {};
  const newInit: RequestInit = init ? { ...init, ...defaultInit } : defaultInit;

  if (newInit.headers) {
    (newInit.headers as Headers).append('Accept', 'application/json');
  } else {
    newInit.headers = new Headers({ Accept: 'application/json' });
  }

  const accessToken = getUser()?.access_token || process.env.REACT_APP_ACCESS_TOKEN;
  if (!accessToken) {
    window.location.reload();
    throw new Error('no access token found');
  } else {
    (newInit.headers as Headers).set('Authorization', `Bearer ${accessToken}`);

    const response = await fetch(request, {
      mode: 'cors',
      credentials: 'include',
      ...newInit,
    });

    const text = await response.text();
    if (!response.ok) {
      let hubApiResponse;
      try {
        hubApiResponse = JSON.parse(text);
      } catch (e) {
        hubApiResponse = {};
      }
      throw new ApiError(hubApiResponse?.message ?? 'no message found', response.status, hubApiResponse?.errors);
    }
    return text;
  }
};

function getUser() {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`,
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

export default {
  hubFetch,
};
