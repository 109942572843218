import DisbursementSummary from '../disbursements/models/DisbursementSummary';
import Disbursement from '../disbursements/models/Disbursement';
import DisbursementView from '../disbursements/models/DisbursementView';
import Link from '../shared/models/Link';
import HubFetch from './hub-fetch';
import AdvancedSearchCriteria from '../search/models/AdvancedSearchCriteria';
import ApiError from './ApiError';
import BasicSearchCriteria from '../search/models/BasicSearchCriteria';
import { TotalAmounts } from '../disbursements/DataCard/DataCardContainer';
import CancelablePromise from '../util/CancelablePromise';

interface ApiGatewayError {
  status: undefined;
  errors: undefined;
}

const headers = new Headers({ 'Content-Type': 'application/json' });
const csvHeaders = new Headers({ Accept: 'text/csv', 'Content-Type': 'application/json' });

export const API_ERROR_400_MESSAGE = 'Unable to get disbursements. Applied filter contains the following errors:';
export const API_ERROR_503_MESSAGE =
  'Your criteria searches for too much data. ' +
  'Please narrow down your search by making your criteria more specific (i.e. use "Earner Name" instead of "Name" or shorten the paid date range)';
export const API_ERROR_DEFAULT_MESSAGE = 'Something went wrong on our end. Please try again later.';

const dateStringAscending = (a: DisbursementSummary, b: DisbursementSummary): number =>
  a.paidDate.localeCompare(b.paidDate);
const mapToDisbursement = (d: DisbursementSummary, index: number): DisbursementSummary => ({ ...d, index });

async function getDisbursementByUrl(disbursementLink: Link): Promise<Disbursement> {
  let response;
  try {
    response = await HubFetch.hubFetch(disbursementLink.href);
  } catch (e) {
    const { status, errors } = e as ApiError;
    throw new ApiError('Unable to load disbursement.', status, errors);
  }
  return JSON.parse(response);
}

async function getTotalAmountsByLink(totalAmountsLink: Link): Promise<TotalAmounts> {
  let response;
  try {
    response = await HubFetch.hubFetch(totalAmountsLink.href);
  } catch (e) {
    const { status, errors } = e as ApiError;
    throw new ApiError('Unable to retrieve total amounts.', status, errors);
  }
  return JSON.parse(response);
}

function getTotalAmountsByLinkWithPromise(totalAmountsLink: Link): CancelablePromise<TotalAmounts> {
  return new CancelablePromise<TotalAmounts>(getTotalAmountsByLink(totalAmountsLink));
}

async function getDisbursementEarningsAsCsv(
  url: Link,
  searchCriteria: SearchCriteria,
  filename: string,
): Promise<void> {
  const response = await HubFetch.hubFetch(url.href, {
    headers: csvHeaders,
    method: 'POST',
    body: JSON.stringify(searchCriteria),
  });

  const blob = new Blob([response], { type: 'text/csv' });
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove(); // afterwards we remove the element again
}

async function getAdjustmentAsCsv(url: Link, filename: string): Promise<void> {
  const response = await HubFetch.hubFetch(url.href, {
    headers: csvHeaders,
    method: 'GET',
  });

  const blob = new Blob([response], { type: 'text/csv' });
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove(); // afterwards we remove the element again
}

type SearchCriteria = AdvancedSearchCriteria | BasicSearchCriteria | undefined;

async function getDisbursements(searchCriteria: SearchCriteria, disbursementsUrl: string): Promise<DisbursementView> {
  const body = JSON.stringify(searchCriteria);
  const method = 'POST';
  const init: RequestInit = { method, body, headers };
  let response;
  try {
    response = await HubFetch.hubFetch(disbursementsUrl, init);
  } catch (e) {
    const { status = 503, errors } = e as ApiError | ApiGatewayError;
    const statusToMessageMap = new Map([
      [400, API_ERROR_400_MESSAGE],
      [503, API_ERROR_503_MESSAGE],
    ]);
    const message = statusToMessageMap.get(status) ?? API_ERROR_DEFAULT_MESSAGE;
    throw new ApiError(message, status, errors);
  }
  const json = JSON.parse(response);
  const disbursements: DisbursementSummary[] = json.disbursementSummaries
    .sort(dateStringAscending)
    .map(mapToDisbursement);

  return {
    disbursementSummaries: disbursements,
    distributionPartner: json.distributionPartner,
  };
}

export default {
  getDisbursementByUrl,
  getTotalAmountsByLink,
  getTotalAmountsByLinkWithPromise,
  getDisbursementEarningsAsCsv,
  getAdjustmentAsCsv,
  getDisbursements,
};
