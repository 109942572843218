export default class PageMetadata {
  pageNumber: number;
  size: number;
  totalElements: number;
  totalPages: number;

  constructor(pageNumber: number, size: number, totalElements: number, totalPages: number) {
    this.pageNumber = pageNumber;
    this.size = size;
    this.totalElements = totalElements;
    this.totalPages = totalPages;
  }
}
