/* eslint-disable no-void */
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { useAuth } from 'react-oidc-context';
import Navbar from './navbar/Navbar/Navbar';
import Footer from './footer/Footer/Footer';
import HubView from './shared/models/HubView';
import HubApi from './api/hub-api';
import Hub from './Hub';
import Operations from './Operations';
import LoadingPage from './shared/components/Loading/LoadingPage';
import useScript from './shared/hooks/useScript';

const App: React.FC = () => {
  const [view, setView] = useState<HubView | undefined>();
  const [loggedIn, setLoggedIn] = useState(true);
  const [hasTriedLogin, setHasTriedLogin] = useState(false);
  const auth = useAuth();

  useScript({
    url: `https://js-cdn.dynatrace.com/jstag/16ab023090d/${process.env.REACT_APP_DYNATRACE_ENV}_complete.js`,
    type: 'text/javascript',
    crossOrigin: 'anonymous',
  });

  const logout = useCallback(async () => {
    const args = {
      id_token_hint: auth.user?.id_token,
      client_id: auth.settings.client_id,
      extraQueryParams: {
        ...(process.env.PUBLIC_URL === '/personas' && { logout_uri: auth.settings.redirect_uri }),
      },
    };
    await auth.removeUser();
    await auth.signoutRedirect(args);
    setLoggedIn(false);
  }, [auth]);

  const login = useCallback(async () => {
    await auth.signinRedirect();
  }, [auth]);

  useEffect(() => {
    const fetch = async () => {
      const newView = await HubApi.get();
      setView(newView);
      setHasTriedLogin(true);
    };
    if (auth.isAuthenticated) {
      fetch();
    }
    if (!auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !auth.error && !hasTriedLogin) {
      login();
    }
  }, [auth, login, hasTriedLogin]);

  if (auth.isAuthenticated) {
    return (
      <CookiesProvider>
        <>
          <Navbar
            user={view?.user}
            feedback={view?._links?.feedback}
            loggedIn={loggedIn}
            logout={logout}
            login={login}
          />
          {view?._links !== undefined ? (
            <Switch>
              <Route path="/operations">
                <Operations
                  user={view.user}
                  securityProfilesLink={view._links.securityProfiles}
                  notificationsLink={view._links.notifications}
                  saveFirmContactLink={view._links.saveFirmContact}
                  saveFirmDelegateLink={view._links.saveFirmDelegate}
                />
              </Route>
              <Route exact path="/">
                <Hub view={view} />
              </Route>
            </Switch>
          ) : (
            <LoadingPage />
          )}
          <Footer feedbackHref={view?._links?.feedback?.href} />
        </>
      </CookiesProvider>
    );
  }
  return <div data-testid="non-auth-view" />;
};
export default App;
