import Earning from './Earning';
import Navigation from '../../shared/models/Navigation';
import PageMetadata from '../../shared/models/PageMetadata';

export default class EarningsPage {
  earnings: Earning[];
  navigation: Navigation;
  metadata: PageMetadata;

  constructor(earnings: Earning[], navigation: Navigation, metadata: PageMetadata) {
    this.earnings = earnings;
    this.navigation = navigation;
    this.metadata = metadata;
  }
}
