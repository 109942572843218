import React, { useEffect, useState } from 'react';
import InputLabel from '../InputLabel/InputLabel';

interface Props {
  id: string;
  onChange: (value: string) => void;
  options: SelectOptions[];
  label?: string;
  required?: boolean;
  value?: string;
}

interface SelectOptions {
  key: string;
  value: string;
}

const SelectFormGroup: React.FC<Props> = (props) => {
  const { onChange, options, label, required, id, value } = props;

  const [currentValue, setCurrentValue] = useState<string>();

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
    onChange(newValue);
  };

  return (
    <div className="form-group">
      {label && <InputLabel htmlFor={id} labelText={label} isRequired={required} />}
      <select id={id} value={currentValue} className="form-control" required={required} onChange={onSelectChange}>
        {options.map((opt) => (
          <option key={opt.key} value={opt.value}>
            {opt.key}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectFormGroup;
