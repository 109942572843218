import React from 'react';

export interface InputLabelProps {
  htmlFor: string;
  isRequired?: boolean;
  labelText: string;
}

const InputLabel: React.FC<InputLabelProps> = ({ htmlFor, isRequired, labelText }) => (
  <label htmlFor={htmlFor} className={isRequired ? 'is-required' : ''}>
    {labelText}
  </label>
);
export default InputLabel;
