class CancelablePromise<T> {
  promise: Promise<T>;
  canceled: boolean;

  constructor(promise: Promise<T>) {
    this.canceled = false;
    this.promise = promise.then((t: T) => {
      if (this.canceled) {
        throw new Error('cancelled');
      }

      return t;
    });
  }

  public cancel() {
    this.canceled = true;
  }
}

export default CancelablePromise;
