import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import InputLabel from '../InputLabel/InputLabel';

interface Props {
  id: string;
  label?: string;
  required?: boolean;
  onChange?: (date: Date | undefined) => void;
  invalid?: boolean;
  feedback?: string;
  value?: Date;
}

type InputHTMLAttributesOverriden = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'value' | 'onChange'
>;

type DateTimeInputProps = Props & InputHTMLAttributesOverriden;

const DateTimePickerFormGroup = (props: DateTimeInputProps) => {
  const { id, label, required, onChange, feedback, invalid, value, ...rest } = props;
  const [dateString, setDateString] = useState<string>('');
  const [currentFeedback, setCurrentFeedback] = useState<string | undefined>();
  const { placeholder, type, maxLength, ...htmlInputAttrs } = rest;
  const datetimePickerFormat = 'yyyy-MM-ddTHH:mm';
  const datetimeFormatEscapedForDatefns = datetimePickerFormat.replace('T', "'T'");

  useEffect(() => {
    setCurrentFeedback(feedback);
  }, [feedback]);

  useEffect(() => {
    setDateString((previousDate) => (value ? format(new Date(value), datetimeFormatEscapedForDatefns) : previousDate));
  }, [value, datetimeFormatEscapedForDatefns]);

  const uniqueId = `${id}Element`;
  let formGroupClassName = 'form-group';
  if (invalid || currentFeedback) {
    formGroupClassName = `${formGroupClassName} has-error`;
  }

  const onDatePickerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setDateString(newValue);
    if (event.target.checkValidity() && Date.parse(newValue)) {
      if (onChange) onChange(new Date(newValue));
      setCurrentFeedback(undefined);
    } else {
      setCurrentFeedback('Date must be in this format: MM/dd/yyyy hh:mm AM/PM');
      if (onChange) onChange(undefined);
    }
  };

  return (
    <div className={formGroupClassName}>
      {label && <InputLabel htmlFor={uniqueId} labelText={label} isRequired={required} />}
      <input
        id={uniqueId}
        type="datetime-local"
        placeholder={datetimePickerFormat}
        className="form-control"
        onChange={onDatePickerChange}
        value={dateString}
        required={required}
        pattern="\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])T\d{2}:\d{2}"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...htmlInputAttrs}
      />
      {currentFeedback && <span className="has-error-message help-block">{currentFeedback}</span>}
    </div>
  );
};

export default DateTimePickerFormGroup;
