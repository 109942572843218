import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { tz } from 'moment-timezone';
import Notification from '../models/Notification';
import { getAllNotificationsForOpsUser } from '../../api/notifications-api';
import Link from '../../shared/models/Link';
import LoadingSpinner from '../../shared/components/Loading/LoadingSpinner';

interface Props {
  notificationsLink: Link;
}

const ViewNotifications = (props: Props) => {
  const history = useHistory();
  const { notificationsLink } = props;
  const [notifications, setNotifications] = useState<Notification[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const currentTimezone = tz.guess();

  const navigateToCreateNotification = () => {
    history.push('/operations/notifications/new');
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      setIsLoading(true);
      const newNotifications = await getAllNotificationsForOpsUser(notificationsLink);
      setNotifications(newNotifications);
    };

    fetchNotifications();
    setIsLoading(false);
  }, [notificationsLink]);

  const onEditClick = (id: string): void => {
    history.push(`/operations/notifications/${id}/edit`);
  };

  const notificationRows =
    notifications && notifications.length !== 0 ? (
      notifications.map((n) => (
        <tr key={n.id} role="row">
          <td role="cell">{n.title}</td>
          <td role="cell">
            <span className={`label label-${n.notificationLevel.toLowerCase()}`}>{n.notificationLevel.valueOf()}</span>
          </td>
          <td role="cell">{format(new Date(n.startDateTime), 'MM/dd/yyyy hh:mm a')}</td>
          <td role="cell">{format(new Date(n.endDateTime), 'MM/dd/yyyy hh:mm a')}</td>
          <td role="cell">
            <span className={`label label-${n.enabled ? 'success' : 'default'}`}>
              {n.enabled ? 'enabled' : 'disabled'}
            </span>
          </td>
          <td role="cell">
            <button
              id="editNotificationButton"
              type="button"
              className="btn btn-link"
              onClick={() => onEditClick(n.id)}
            >
              <span className="fa fa-edit" />
            </button>
          </td>
        </tr>
      ))
    ) : (
      <tr key={0} role="row">
        <td colSpan={6} className="text-center" role="cell">
          <em>No notifications to show</em>
        </td>
      </tr>
    );

  const loadingSpinner = (
    <tr key={0} className="no-print">
      <td colSpan={6}>
        <LoadingSpinner />
      </td>
    </tr>
  );

  return (
    <>
      <h1>Notifications</h1>
      <div className="row pull-right">
        <button
          type="button"
          id="createNotificationButton"
          className="btn btn-primary"
          onClick={navigateToCreateNotification}
        >
          New Notification
        </button>
      </div>
      <div className="util-padding-top-50">
        <table className="table table-hover">
          <thead>
            <tr>
              <th role="columnheader">Title</th>
              <th role="columnheader">Level</th>
              <th role="columnheader">{`Start Date (${currentTimezone})`}</th>
              <th role="columnheader">{`End Date (${currentTimezone})`}</th>
              <th role="columnheader">Enabled</th>
              <th role="columnheader">Edit</th>
            </tr>
          </thead>
          <tbody>{isLoading ? loadingSpinner : notificationRows}</tbody>
        </table>
      </div>
    </>
  );
};

export default ViewNotifications;
