import { useUserPreferences } from '../../user-preferences/UserPreferencesProvider';
import RowSelector from './RowSelector/RowSelector';
import ColumnSelector from './ColumnSelector/ColumnSelector';
import './SelectorContainer.css';
import Column from '../../shared/models/Column';

interface Props {
  columnDefinitions: Column[];
  defaultColumns: string[];
}

const SelectorContainer = (props: Props) => {
  const { columnDefinitions, defaultColumns } = props;
  const { userPreferences, dispatch } = useUserPreferences();

  const onCollapseColumnSelectionToggled = () => {
    if (dispatch) {
      dispatch({ type: 'column-selection-collapsed' });
    }
  };

  if (!userPreferences?.columnSelectionCollapsed) {
    return (
      <div id="SelectorContainer" className="col-md-12 hub-well-border">
        <form>
          <div className="row">
            <button
              type="button"
              className="btn btn-default btn-sm collapsable-tab-button collapsable-tab-button-expanded"
              onClick={onCollapseColumnSelectionToggled}
              title="collapse column and row menu"
            >
              <em className="fa fa-caret-left" aria-label="collapse columns menu" />
            </button>
          </div>
          <RowSelector />
          <ColumnSelector columnDefinitions={columnDefinitions} defaultColumns={defaultColumns} />
        </form>
      </div>
    );
  }
  return (
    <button
      type="button"
      className="btn btn-default btn-sm collapsable-tab-button collapsable-tab-button-collapsed"
      onClick={onCollapseColumnSelectionToggled}
      title="expand column and row menu"
    >
      <em className="fa fa-caret-right" aria-label="expand column menu" />
      <div className="title">Cells to Show</div>
    </button>
  );
};

export default SelectorContainer;
