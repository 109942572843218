import Formatter from '../../util/formatter';
import DownloadButton from '../DownloadButton/DownloadButton';
import './PayDetails.css';
import Disbursement from '../models/Disbursement';
import Link from '../../shared/models/Link';

// eslint-disable-next-line no-shadow
export enum CompensationType {
  DISBURSEMENT,
  ADJUSTMENT,
}

interface Props {
  disbursement: Disbursement;
  compensationType: CompensationType;
}

const PayDetails = (props: Props) => {
  const { disbursement, compensationType } = props;
  const csvLink =
    compensationType === CompensationType.DISBURSEMENT
      ? (disbursement._links.advancedSearch as Link)
      : disbursement._links.self;

  return (
    <div id="PayDetails">
      <div className="row payee-name">
        <div className="col-xs-12">
          <h1 className="align-left util-margin-bottom-0">{disbursement.payee.name?.fullName || ''}</h1>
        </div>
      </div>
      <div className="row payee-pay-detail">
        <div className="col-xs-3 payee">
          <h3 className="paid-date-method align-left">
            {`Paid on ${Formatter.isoToLongDate(disbursement.paidDate, true)} via ${disbursement.payMethod}`}
          </h3>
          <table className="table table-hover util-margin-bottom-0">
            {disbursement.payeeRoles && (
              <thead>
                <tr>
                  <th>Role</th>
                  <th>Paid Amount</th>
                </tr>
              </thead>
            )}
            <tbody>
              {disbursement.payeeRoles &&
                disbursement.payeeRoles?.map((role) => (
                  <tr key={role.role}>
                    <td>{role.role}</td>
                    <td>{`$${Formatter.numberToUSD(role.paidAmount)}`}</td>
                  </tr>
                ))}
              {disbursement.totalPaidAmount !== undefined && (
                <tr className="tr-divider">
                  <td>
                    <strong>Total Paid Amount</strong>
                  </td>
                  <td>
                    <strong>{`$${Formatter.numberToUSD(disbursement.totalPaidAmount)}`}</strong>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {csvLink && (
          <div className="col-xs-9 text-right no-print print-and-export">
            <DownloadButton
              getCsvLink={csvLink}
              paidDate={disbursement.paidDate || ''}
              compensationType={compensationType}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PayDetails;
