import { useQuery } from 'react-query';
import { findAll } from '../../api/saved-search-api';

function fetchSearches() {
  return findAll();
}

export default function useSavedSearches() {
  return useQuery(['saved-searches'], fetchSearches);
}
