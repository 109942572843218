import { useState } from 'react';
import SecurityProfile from './models/SecurityProfile';
import ToggleDropdownSelector from '../search/ToggleDropdownSelector/ToggleDropdownSelector';
import AdvancedSearchOption from '../search/models/AdvancedSearchOption';
import * as SecurityProfilesApi from '../api/security-profile-api';
import Alert from '../shared/components/Alert/Alert';
import ApiError from '../api/ApiError';
import SecurityProfilesTable from './SecurityProfilesTable';
import Link from '../shared/models/Link';

const searchOptions: AdvancedSearchOption[] = [
  {
    key: 'enterpriseIds',
    label: 'Enterprise Id',
  },
];

interface Props {
  securityProfilesLink: Link;
}

const ViewSecurityProfiles = (props: Props) => {
  const {
    securityProfilesLink: { href: securityProfilesUrl },
  } = props;
  const [securityProfiles, setSecurityProfiles] = useState<SecurityProfile[]>([]);
  const [currentField, setCurrentField] = useState<AdvancedSearchOption>();
  const [searchInputText, setSearchInputText] = useState<string>();
  const [apiError, setApiError] = useState<ApiError>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputText(event.target.value);
  };

  const applySearch = async () => {
    setSecurityProfiles([]);
    const key = currentField?.key;
    if (searchInputText && key) {
      const ids = searchInputText.split(',').map((id) => id.trim());
      const searchRequest = { [key]: ids };
      setLoading(true);
      setApiError(undefined);
      try {
        const profiles = await SecurityProfilesApi.getSecurityProfile(securityProfilesUrl, searchRequest);
        setSecurityProfiles(profiles);
      } catch (e) {
        setApiError(e as ApiError);
      }
      setLoading(false);
    }
  };

  const handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      await applySearch();
    }
  };

  const placeholder = `Filter on single, or comma separated: ${currentField?.label}s (ex: id1, id2)`;
  return (
    <>
      <h1>Security Lookup</h1>
      <div className="row util-padding-top-50">
        <div className="col-md-10">
          <div className="input-group">
            <ToggleDropdownSelector options={searchOptions} onChange={setCurrentField} />
            <input
              id="search"
              placeholder={placeholder}
              type="text"
              value={searchInputText || ''}
              className="form-control"
              onChange={onSearchInputChange}
              onKeyPress={(e) => handleKeyPress(e)}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="btn-group">
            <button type="button" className="btn btn-primary apply-filter" onClick={applySearch}>
              Apply Search
            </button>
          </div>
        </div>
      </div>
      <div className="util-padding-top-50">
        <SecurityProfilesTable isLoading={isLoading} securityProfiles={securityProfiles} />
        {apiError && !isLoading && (
          <Alert
            severity="danger"
            message={apiError.message ? apiError.message : ' Unspecified Error - no message'}
            errorList={apiError.errors}
          />
        )}
      </div>
    </>
  );
};

export default ViewSecurityProfiles;
