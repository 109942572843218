import React from 'react';
import InputLabel from '../InputLabel/InputLabel';

export interface Props {
  inputId: string;
  labelText: string;
  onChange: (value: string) => void;
  required?: boolean;
  placeholder?: string;
  maxCharacters?: number;
  value?: string;
  invalid?: boolean;
  feedback?: string;
}

const InputFormGroup: React.FC<Props> = (props) => {
  const { inputId, labelText, onChange, required, placeholder, maxCharacters, value, invalid, feedback } = props;

  let formGroupClassName = 'form-group util-margin-bottom-50';
  if (invalid) {
    formGroupClassName = `${formGroupClassName} has-error`;
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;
    onChange(inputText);
  };

  return (
    <div className={formGroupClassName}>
      <InputLabel htmlFor={inputId} labelText={labelText} isRequired={required} />
      <input
        id={inputId}
        className="form-control"
        onChange={onInputChange}
        placeholder={placeholder}
        required={required}
        maxLength={maxCharacters}
        value={value}
      />
      {feedback && <span className="has-error-message help-block">{feedback}</span>}
    </div>
  );
};
export default InputFormGroup;
