import { RichUtils } from 'draft-js';
import React from 'react';
import { RichTextAreaToolbarProps, StyleButtonProps } from './RichContentEditableToolbar';

interface BlockStyles {
  [index: string]: { utilName: string; iconClassName: string; label: string; title: string };
}

const BlockStyleButton: React.FC<StyleButtonProps> = (props) => {
  const blockStyles: BlockStyles = {
    ul: {
      utilName: 'unordered-list-item',
      iconClassName: 'fa-list-ul',
      label: 'unordered list button',
      title: 'unordered list',
    },
    ol: {
      utilName: 'ordered-list-item',
      iconClassName: 'fa-list-ol',
      label: 'ordered list button',
      title: 'ordered list',
    },
  };

  const { styleName, editorState, onContentChange, disabled } = props;

  const toggleBlockType = (blockStyle: string) => {
    onContentChange(RichUtils.toggleBlockType(editorState, blockStyle));
  };

  const includesBlockType = (blockType: string): boolean => {
    const selection = editorState.getSelection();
    const blockTypeFromCurrentContent = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return blockType === blockTypeFromCurrentContent;
  };

  return (
    <button
      type="button"
      className={`btn StyleButtons-button ${includesBlockType(blockStyles[styleName].utilName) ? 'active' : ''}`}
      aria-label={blockStyles[styleName].label}
      title={blockStyles[styleName].title}
      onClick={() => toggleBlockType(blockStyles[styleName].utilName)}
      disabled={disabled}
      tabIndex={-1}
    >
      <i className={`fa ${blockStyles[styleName].iconClassName}`} />
    </button>
  );
};

const BlockStyleButtons: React.FC<RichTextAreaToolbarProps> = (props) => {
  const { editorState, onContentChange, disabled } = props;
  const blockStyles = ['ul', 'ol'];
  return (
    <div className="btn-group btn-group-sm" role="group">
      {blockStyles.map((blockStyleName) => (
        <BlockStyleButton
          key={`BlockStyleButton-${blockStyleName}`}
          styleName={blockStyleName}
          editorState={editorState}
          onContentChange={onContentChange}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default BlockStyleButtons;
