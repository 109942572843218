import { useState } from 'react';
import FilterOption from '../../models/FilterOption';
import './FilterDropdown.css';

interface Props {
  options: FilterOption[];
  onChange: (value?: FilterOption) => void;
  eventLabelText: string;
}

const FilterDropdown = (props: Props) => {
  const { options, onChange, eventLabelText } = props;
  const [selected, setSelected] = useState<FilterOption>({
    eventTypeText: eventLabelText,
  });

  const createOnClick = (option: FilterOption) => (event: React.MouseEvent) => {
    event.preventDefault();
    setSelected(option);
    onChange(option?.eventTypeText === eventLabelText ? { eventTypeText: '' } : option);
  };

  return (
    <div id="FilterDropdown" className="input-group dropdown-spacing" title={`${eventLabelText} Value`}>
      <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown">
        {selected.eventTypeText || eventLabelText}
        <i className="fa fa-angle-down util-margin-left-20" />
      </button>
      <ul className="dropdown-menu" role="menu">
        <li key="-1">
          <a href="# " onClick={createOnClick({ eventTypeText: eventLabelText })}>
            <em>none</em>
          </a>
        </li>
        <li role="separator" className="divider" />
        {options &&
          options.map((option) => (
            <li key={option.eventTypeText}>
              <a href="# " onClick={createOnClick(option)}>
                {option.eventTypeText}
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default FilterDropdown;
