import FilterOption from '../../models/FilterOption';
import FilterDropdown from '../FilterDropdown/FilterDropdown';

interface Props {
  onChange: (search: string[] | undefined) => void;
  filterOptions: FilterOption[];
  eventLabelText: string;
}

const EarningFilters = (props: Props) => {
  const { onChange, filterOptions, eventLabelText } = props;
  const fireEvent = (filterOption?: FilterOption) => {
    const eventTypeText = filterOption?.eventTypeText;
    const search = eventTypeText ? [eventTypeText] : undefined;

    onChange(search);
  };

  return filterOptions ? (
    <FilterDropdown options={filterOptions} onChange={fireEvent} eventLabelText={eventLabelText} />
  ) : (
    <div />
  );
};

export default EarningFilters;
